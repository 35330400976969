import React, { useState, useEffect, useRef, useContext } from "react";
import axios from "../../../util/axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { clinicImpersonated } from "../../../util/functions";
import { stubString } from "lodash";
import { MoreHoriz } from "@material-ui/icons";
import Lightbox from "react-awesome-lightbox";
import ActiveContext from "../../../Context/Context";
import "react-awesome-lightbox/build/style.css";
import { Thumbnail } from "@shopify/polaris";

const useStyles = makeStyles(()=>{return {
  fontFamilyAlmarai:{
    fontFamily:'Almarai-semi !important'
  },
  fontFamilyEina: {
    fontFamily: "Eina-semi !important",
  },
  wrapper:{ 
    backgroundColor: '#fff', 
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)', 
    borderRadius: '3px',
  },
  color:{
    color: '#637381',
  },
  btn:{
    height: '32px', 
    width: '122px', 
    borderRadius: '4.7px', 
    backgroundColor: '#FF2C46', 
    color:'#fff',
    '&:hover':{
      backgroundColor: '#FF2C46'
    }
  },
  headerWrapper:{
    display: 'flex',
    justifyContent:'space-between', 
    borderBottom:'0.1rem solid #DFE3E8',
    padding: '1rem'
  },
  headerTitle:{
    fontSize: '18px !important',
    letterSpacing: 0,
    lineHeight: '32px',
  },
  commentBox:{
    padding:'1rem', 
    width: '100%',
    overflowY: "scroll",
    height: '264px',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  commentTitle:{
    fontSize: '14px !important',
    letterSpacing: 0,
    lineHeight: '20px',
  },
  comment:{
    backgroundColor: 'rgba(223,227,232,0.38)',
    padding:'0.5rem 1rem',
    borderRadius: '0 6px 6px 0',
  },
  bold:{
    fontWeight: "bold"
  },
  thin:{
    fontWeight:'500'
  },
  oneComment:{
    width:'50%',
    marginBottom: '1rem',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    wordBreak: 'break-word'
  },
  right:{
    marginLeft: 'auto',
    textAlign: 'right'
  },
  left:{
    marginRight: 'auto',
    textAlign: 'left'
  },
  date:{
    width:'fit-content',
    fontSize: '9px !important',
    letterSpacing: 0,
    lineHeight: '20px',
  }
}})
export default function Comments() {
  const classes = useStyles();
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  let perPage = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const [isListEmpty, setIsListEmpty] = useState(true);
  const [items, setItems] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();
  const {
    photoIndex,
    imagesArray,
    handleCloseLargeImage,
    lightboxisOpen,
    handleShowLargeImage
  } = useContext(ActiveContext);

  const commentBoxRef = useRef();
  const commentRef = useRef(null);
  const localtion = useLocation();
  const isCommentNot = localtion?.state?.isCommentNot

  useEffect(()=>{
    commentBoxRef.current.scrollTop = commentBoxRef.current.scrollHeight
  },[items])

  const fetchComments = () =>{
    axios
      .get(
        `admin/v1/users/${id}/comments`)
      .then((result) => {
        result.data?.data?.data && !result.data.data.data.length
          ? setIsListEmpty(true)
          : setIsListEmpty(false);
        setTotalPages(Math.ceil(result.data.data.total / perPage));
        if(result.data.data && result.data.data.data){
          const itemsSorted = result.data.data.data.sort((a,b) => a.id - b.id);
          setItems(itemsSorted);
        }
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    if(commentRef?.current && isCommentNot){
      commentRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }
    const ac = new AbortController()
    fetchComments();
    return () => ac.abort();
  }, [currentPage, commentRef]);

  const unHtmlizeComment = (comment) => {
    if(comment.replace(/<[^>]+>/g, '').trim().length+3
    < comment.replace(/<[^>]+>/g, '').length){
      return comment.replace(/<[^>]+>/g, '').replace('&nbsp;', ' ').trim()+"..."
    } else {
      return comment.replace(/<[^>]+>/g, '').replace('&nbsp;', ' ')
    }
  }

  const emtyState = isListEmpty ? (
    <div
      style={{
        display: "flex",
        justifyContent: "middle",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div style={{ width: "25%" }}>
        <Box component="img" sx={{ width:'11.813rem', height:'11rem'}} src={'/assets/images/emptyList.svg'} />
      </div>
      <Typography>No results found</Typography>
      <br />
    </div>
  ) : null;
  return (
    <Box ref={commentRef} className={classes.wrapper}>
      <Box className={classes.headerWrapper}>
        <Typography className={`${classes.headerTitle} ${classes.color} ${classes.bold} ${i18n.language == 'ar' ? classes.fontFamilyAlmarai : classes.fontFamilyEina}`}>{t('Comments')}</Typography>
        <Button disabled={clinicImpersonated()} onClick={()=> navigate("/dashboard/users/" + id + "/addcomment")} className={classes.btn}>{t('Add-comment')}</Button>
      </Box>
      <div ref={commentBoxRef} className={classes.commentBox}>
          {items && items?.map((res)=>{
            
            let isOwner = false;
            if(res?.admins?.first_name == 'Clinic' ){
              isOwner = true;
            }

            return <Box className={isOwner ? `${classes.oneComment}  ${i18n.language == 'en' ? classes.right : classes.left}`: `${classes.oneComment}`}  key={res.id}>
                <Typography className={isOwner ? `${classes.commentTitle} ${classes.color} ${classes.bold} ${i18n.language == 'en' ? classes.right : classes.left} ${i18n.language == 'ar' ? classes.fontFamilyAlmarai : classes.fontFamilyEina}` : `${classes.commentTitle} ${classes.color} ${classes.bold} ${i18n.language == 'ar' ? classes.fontFamilyAlmarai : classes.fontFamilyEina}`}>{isOwner ? 'Me' : res?.admins?.first_name}</Typography>
                <Typography className={`${classes.comment} ${classes.color} ${classes.thin} ${i18n.language == 'ar' ? classes.fontFamilyAlmarai : classes.fontFamilyEina}`}>{unHtmlizeComment(res?.comment)} <div style={{display:"flex", gap: "10px",flexWrap:"wrap"}}>
                {res.images_url&& res.images_url.map((image,key)=>
        <div onClick={()=>{handleShowLargeImage(res.images_url,key)}}>
          <Thumbnail
          size="small"
          source={
            image.image
              ? image.image
              : ""
          }/>
        </div>  )}</div></Typography>
                
                <Typography className={isOwner ? `${classes.date} ${classes.color} ${classes.thin} ${i18n.language == 'ar' ? classes.fontFamilyAlmarai : classes.fontFamilyEina}` : `${classes.date} ${classes.color} ${classes.thin} ${i18n.language == 'en' ? classes.right : classes.left} ${i18n.language == 'ar' ? classes.fontFamilyAlmarai : classes.fontFamilyEina}`}>{moment(res?.created_at).add(2, 'hours').format('DD/MM/YYYY h:mm A')}</Typography>
            </Box>
          })}
      </div>
      { lightboxisOpen && imagesArray?.length &&  <Lightbox startIndex={photoIndex} onClose={() => handleCloseLargeImage()} images={imagesArray.map((val) => val.image !== null && val.image)}></Lightbox>}
    </Box>
  );

}
