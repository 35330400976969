import { Box, Button, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { clinicImpersonated } from '../../util/functions'

const useStyles = makeStyles(()=>{return{
    fontFamilyAlmarai:{
        fontFamily:'Almarai-semi !important'
    },
    fontFamilyEina: {
        fontFamily: "Eina-semi !important",
    },
    flexBox:{
        display: 'flex', 
        justifyContent:'space-between'
    },
    semiBold:{
        fontWeight: '600',
    },
    midTitle:{
        fontSize: '14px !important',
        letterSpacing: 0,
        lineHeight: '20px',
    },
    imgBox:{
        display:'flex',
        flexDirection:'column', 
        width:'100%',
    },
    widthSM:{
        width:'10rem'
    },
    btn:{
        padding:0, 
        fontFamily: 'Helvetica', 
        fontSize: '11px !important',  
        letterSpacing: 0, 
        lineHeight: '13px',
        textAlign: 'right',  
        color: '#007ACE',
        '&:hover':{
            backgroundColor: '#FFF'
        }
    },
    color:{
        color: '#637381',
    },
}})
export default function XRayFileList({userId, imagePreviewXRay ,imagePreviewGumScore}) {
    const navigate = useNavigate();
    const classes =useStyles();
    const { t, i18n } = useTranslation();
  return (
    <Box>
        <Box className={`${classes.imgBox}`}>
             {imagePreviewXRay &&
             <Box>
                <Box className={classes.flexBox} style={{marginTop:'1rem'}}>
                    <Typography className={`${classes.color} ${classes.midTitle} ${classes.semiBold} ${classes.color} ${i18n.language == 'ar' ? classes.fontFamilyAlmarai : classes.fontFamilyEina}`}>{t('X-Ray')}</Typography>
                    <Button onClick={()=> navigate(`/dashboard/users/${userId}/editimpressionsandphotosstep4`)} className={classes.btn}>{ clinicImpersonated() ? t('View') : t('Edit')}</Button>
                </Box>
                <Box component="img" style={{marginTop: '1rem'}} sx={{ height: '69.87px', width:'70.31px'}} src={imagePreviewXRay} />
            </Box>
             }
             {imagePreviewGumScore && 
             <Box>
                <Box className={classes.flexBox} style={{marginTop:'1rem'}}>
                    <Typography className={`${classes.color} ${classes.midTitle} ${classes.semiBold} ${classes.color} ${i18n.language == 'ar' ? classes.fontFamilyAlmarai : classes.fontFamilyEina}`}>{t('Gum-Score')}</Typography>
                    <Button onClick={()=> navigate(`/dashboard/users/${userId}/editimpressionsandphotosstep4`)} className={classes.btn}>{clinicImpersonated() ? t('View') : t('Edit')}</Button>
                </Box>
                <Box component="img" style={{marginTop: '1rem'}} sx={{ height: '69.87px', width:'70.31px'}} src={imagePreviewGumScore} />
            </Box>
             }
        </Box>
    </Box>
  )
}
