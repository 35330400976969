import { Avatar, Badge, Box, Button, IconButton, makeStyles, Menu, MenuItem, RadioGroup, Typography } from '@material-ui/core'
import React, { useEffect, useState, useContext } from 'react'
import NotificationsIcon from '@mui/icons-material/Notifications';
import theme from '../../lib/theme';
import Cookies from 'js-cookie';
import { Link, useNavigate} from 'react-router-dom';
import ClinicNotification from '../Notification/ClinicNotification';
import CommentIcon from '@mui/icons-material/Comment';
import { useTranslation } from 'react-i18next';
import { TabsControllerContext } from '../../Context/Tabs/TabsProvider';
import { clinicImpersonated } from '../../util/functions';
import CloseIcon from "@mui/icons-material/Close";
import i18n from '../../util/i18n';
import AccessTimeIcon  from '@mui/icons-material/AccessTime';
import axios from '../../util/axios';
const useStyles = makeStyles(() => ({
  learnMoreBtn: {
    color: "white",
    fontSize: "16px"
  },
  fontFamilyAlmarai:{
      fontFamily:'Almarai-semi !important'
    },
    fontFamilyEina: {
        fontFamily: "Eina-semi !important",
    },
    root: {
      backgroundColor: '#f6f6f7'
    },
    avatar: {
        backgroundColor: "white",
        width: 50,
        height: 50,
        color: "#ff2c46"
    },
    logoWrapper: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 10,
    },
    logo: {
        maxWidth: 130,
        cursor:"pointer"
    },
    eyewaLogo: {
      maxWidth: 100,
    },
    wrapper: {
        display: "flex",
        height: 70,
        width: "100%",
        position:"fixed",
        zIndex:100,
        justifyItems: "center",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "space-between",
        padding: 30,
        backgroundColor: "#ff2c46"
    },
    arabicWrapper: {
      display: "flex",
      flexDirection:"row-reverse",
      height: 70,
      width: "100%",
      position:"fixed",
      zIndex:100,
      justifyItems: "center",
      alignContent: "center",
      alignItems: "center",
      justifyContent: "space-between",
      padding: 30,
      backgroundColor: "#ff2c46"
    },
    notificationBox: {
        display: "flex",
        justifyItems: "center",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "space-between",
        paddingLeft: 30,
        paddingRight: 30,
        paddingTop: 70, 
    },
    container: {
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    welcomeTitle: {
      minWidth: 'fit-content',
      fontSize: 25,
      display: "flex",
      gap: 5,
    },
    firstName: {
        fontWeight:'bold',
        fontSize: '20px!important'
    },
    welcomeTitleText: {
        fontSize: '19.6px!important',
        fontWeight:'600',
    },
    notificationIcon: {
        transform: "scale(1.5)",
        color: '#ffc107'
    },
    notificationTab: {
      width: '100%',
      height: '100%',
      padding: 20,
    },
    layoutDiv: {
      display:"grid",
      // paddingBottom:"80px",
      gridTemplateColumns:"1fr",
      ['@media only screen and (max-width: 900px) '] : {
        display: 'grid',
        gridTemplateColumns:"100% !important"
       },
    },
    popper: {
        border: "1px white solid",
        width: 500,
        height: 500,
        position: 'absolute',
        backgroundColor: theme.palette.background.main
    },
    navIcons: {
      display: "flex",
      alignContent:"center",
      gap:"10px",
    },
    notiticationTabHeader: {
      height: 40,
      color: 'white', 
      backgroundColor:"#ff2c46", 
      padding: 10, 
      margin:"0",
    },
    sideNav: {
      backgroundColor:"#fff",
      boxShadow: "0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
      position:"fixed",
      width:"15%",
      paddingTop: 80,
      fontSize: "10px",
      display:"grid",
      gap: 12,
      paddingLeft: 10,
      paddingBottom:"100%",
      ['@media only screen and (max-width: 900px) '] : {
          display: 'none'
         },
    },
    createButton: {
      padding: '10px 40px',
      fontWeight:'600',
      backgroundColor: '#8EE2ED',
      color: '#ffffff'
    },
    header: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      padding: '2% 0',
      alignItems: 'center',
    },
    closeWrapper: {
      position: 'absolute',
      display: 'flex',
      columnGap: 20,
      alignItems: 'center',
    },
    close: {
      cursor: 'pointer',
      '&:hover': {
        opacity: 0.7,
      },
    },
    whiteBg: {
      background: 'white',
    },
    addText: {
      fontSize: 16,
      fontFamily: i18n.language == 'en' ? 'Eina-semi' : 'Almarai-semi',
    },
}))
const Layout = (props) => {
    const { isProfile } = props;
    let navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const classes = useStyles()
    const userName = Cookies.get('clinic_name');
    const {
      lang,
      clinicNotifications,
      actions: {
        setLang,
        fetchClinicNotifications,
      },
    } = useContext(TabsControllerContext);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorElNotification, setAnchorElNotification] = React.useState(null);
    const [isTime, setIsTime] = React.useState(0);
    const [isComment, setIsComment] = React.useState(0);
    const [clinicContent, seClinicContent] = React.useState({});
    const [commentNotifications, setCommentNotifications] = useState({
      size_all: 0,
      pending: 0,
      notifications: []
    });
  const handleAddPatient = () => {
    navigate('/dashboard/patient/add');
  }
  const[updatenotifications,setUpdate]=useState(0)
    const open = Boolean(anchorEl);
    const openNotification = Boolean(anchorElNotification);
    const { refreshComment } = useContext(TabsControllerContext);
    const handleClickNotification = (event) => {
      setIsComment(0)
      setIsTime(0)
      setAnchorElNotification(event.currentTarget);
    };  
    const handleClickTime = (event) => {
      setIsTime(1)
      setAnchorElNotification(event.currentTarget);
    };  
    const handlecommentClickNotification = (event) => {
      setIsComment(1)
      setIsTime(0)
      setAnchorElNotification(event.currentTarget);
    };  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleCloseNotificationTab = () => {
      setAnchorElNotification(null);
    };
    const handleCloseProfileTab = () => {
      setAnchorEl(null);
    };
    const ChangeLanguageHandler = (e) => {
      if (i18n.language === 'en') {
          i18n.changeLanguage('ar').then(() => {
              setLang('ar')
              Cookies.set('i18next', 'ar');
          })
      }
      else {
          i18n.changeLanguage('en').then(() => {
            setLang('en')
            Cookies.set('i18next', 'en');
          })
      }
  }
    useEffect(() => {
      fetchClinicNotifications();
    }, [updatenotifications, refreshComment])

    useEffect(() => {
      if(clinicNotifications?.data_comment) {
        setCommentNotifications(clinicNotifications.data_comment);
      }
   }, [clinicNotifications])
   useEffect(() => {
    const fetchData = async () => {
        try {
            const response = await axios.get(`admin/v1/clinic/profile?clinic_id=${Cookies.get('clinic_id')}`);
            seClinicContent(response.data.data.clinic_info.content)
        } catch (err) {
            console.log(err);
        } 
    };
    fetchData(); 
}, []);

    return (
       <Box className={props.hideHeader ? classes.whiteBg : ''}>
      {
        !props.hideHeader ?
            <Box style={{
      display: "flex",
      flexDirection:"row",
      height: 70,
      width: "100%",
      position:"fixed",
      zIndex:100,
      justifyItems: "center",
      alignContent: "center",
      alignItems: "center",
      justifyContent: "space-between",
      padding: 30,
      backgroundColor: "#ff2c46"
    }}>
      <div className={classes.logoWrapper}>
        <img className={classes.logo} src={"/assets/images/logo.svg"} alt="Basma-logo" onClick={() => navigate('/dashboard')} />
        {clinicNotifications?.is_mall_collaboration ? <img className={classes.eyewaLogo} src='/assets/images/EYEWA-LOGO-white.png' alt='eyewa-logo' /> : null}
      </div>
         <div style={lang == 'en' ? {display:"flex", flexDirection:"row-reverse" , gap: '10px', alignContent: 'center', justifyItems: 'center', alignItems:"center"} : { display:"flex", flexDirection:"row-reverse", gap: '10px', alignContent: 'center', justifyItems: 'center', alignItems:"center"}}> 
         <Avatar
          id="demo-positioned-button"
          aria-controls={open ? 'demo-positioned-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          className={classes.avatar}
          />
           <Link className={classes.learnMoreBtn} to="/dashboard/instructions">{t("learn-more")}</Link>
          <p style={{ cursor: 'pointer', color: 'white', fontWeight:"bold" }} onClick={ChangeLanguageHandler} className="language">{lang === 'ar' ? 'EN' : 'عربى'}</p>
        </div>
          <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl}
              open={open}
              onClose={handleCloseProfileTab}
              anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
              }}
              transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
              }}
              >
              <RadioGroup name="radio-buttons-group">
             <MenuItem to="/dashboard/billing" component={Link}>{t('billing')}</MenuItem>
            <MenuItem disabled={clinicImpersonated()} onClick={()=> {
              Cookies.remove('access_token_clinic');
              Cookies.remove('clinic_name');
              Cookies.remove('clinic_address');
              Cookies.remove('clinic_id');
              Cookies.remove('is_scan_at_home');
              if(Cookies.get('type'))
                Cookies.remove('type');
              navigate('/')
              }}>{t('Logout')}</MenuItem>
            </RadioGroup>
          </Menu>
        </Box> :
        <Box className={classes.header}>
          <div className={classes.logoWrapper}>
            <img className={classes.logo} src='/assets/images/Basmacom Logo' alt='Basma-logo' />
            <img className={classes.logo} src='/assets/images/EYEWA-LOGO-green.png' alt='eyewa-logo' />
          </div>
          <div className={classes.closeWrapper} style={lang == 'en' ? {right: '2%'} : {left: '2%'}}>
            <p style={{ cursor: 'pointer', fontWeight:"bold" }} onClick={ChangeLanguageHandler} className="language">{lang === 'ar' ? 'EN' : 'عربى'}</p>
            <CloseIcon className={classes.close} onClick={() => navigate('/dashboard')} />
          </div>
        </Box>
      }
      <div className={classes.layoutDiv}>
      <div>
         <Box className={props.hideHeader ? '' : classes.notificationBox}>
            {(!isProfile && !props.hideHeader) && (
             <Box className={classes.container}>
            <Box className={classes.welcomeTitle}>
                <Typography className={`${classes.welcomeTitleText} ${i18n.language == 'ar' ? classes.fontFamilyAlmarai : classes.fontFamilyEina}`}>{t('Welcome')}</Typography>
                <Typography className={`${classes.firstName} ${i18n.language == 'ar' ? classes.fontFamilyAlmarai : classes.fontFamilyEina}`}>{userName},</Typography>
            </Box>
        <Box style={{display:"flex", justifyContent:"space-between", alignItems:'center', gap:"10px"}}>
        {clinicContent&&
          <IconButton style={{position:"relative"}} >
            <Box onClick={(event) => handleClickTime(event)}>
              <AccessTimeIcon
                id="demo-positioned-button1"
                aria-controls={openNotification ? 'demo-positioned-menu1' : undefined}
                aria-haspopup="true"
                aria-expanded={openNotification ? 'true' : undefined} 
                className={classes.notificationIcon} />
            </Box>
          </IconButton>
         }
          <IconButton
              style={{position:"relative"}}
            >

              <Box onClick={(event) => handlecommentClickNotification(event)}>
                <Badge badgeContent={commentNotifications.pending} color="error">
                  <CommentIcon
                    id="demo-positioned-button1"
                    aria-controls={openNotification ? 'demo-positioned-menu1' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openNotification ? 'true' : undefined} 
                    className={classes.notificationIcon} />
                </Badge>
              </Box>
          </IconButton>
         <Box>
         <IconButton
            style={{position:"relative"}}
          >
            <Box onClick={(event) => handleClickNotification(event, '')}>
              <Badge badgeContent={clinicNotifications.pending} color="error">
                <NotificationsIcon      
                id="demo-positioned-button1"
                aria-controls={openNotification ? 'demo-positioned-menu1' : undefined}
                aria-haspopup="true"
                aria-expanded={openNotification ? 'true' : undefined} 
                className={classes.notificationIcon} />
              </Badge>
            </Box>
          </IconButton>
          <ClinicNotification  anchorElNotification={anchorElNotification}
              updatenotifications={updatenotifications}
              openNotification={openNotification}
              handleCloseNotificationTab={handleCloseNotificationTab} isComment={isComment} isTime={isTime} clinicContent={clinicContent} data={isComment ? commentNotifications : clinicNotifications} setUpdate={setUpdate}/>
         </Box>
     
      <Box>
      {!props.hideCreate && <Button
          disabled={clinicImpersonated()}
          className={`${classes.createButton} ${i18n.language == 'ar' ? classes.fontFamilyAlmarai : classes.fontFamilyEina}`}
          id="demo-positioned-button-create"
          onClick={handleAddPatient}
        >
          <p className={classes.addText}>{t('Add-Patient')}</p>
        </Button>
      }
      </Box>
        </Box>
        </Box>)}
        </Box>
        <div>{props.children}</div>
      </div>
      </div>
        </Box>
    )
}
export default Layout;