import React, { useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import ScansDropZone from "./ScansDropZone";
import axios from "../../util/axios";
import axiosAws from "axios";
import JSZip from "jszip";
import { imageValueV2 } from "../../util/functions";
import i18n from "../../util/i18n";

const ScanFiles = ({
    imagePreview,
    appointmentType,
    disable,
    setSuccess,
    appointmentId,
    handleDataFetched,
    userId,
    classes,
    setErrorMsg,
    scannerPlaceholders,
    t,
}) => {
    const [uploadProgress, setUploadProgress] = useState({});
    const [disabled, setDisabled] = useState(false);
    const [substituteScan, setSubstituteScan] = useState({
        document: false,
        lower_scan: false,
        bite_scan_1: false,
        bite_scan_2: false,
        scans_folder: false,
    });
    const [uploadedFile, setUploadedFile] = useState({
        document: 0,
        lower_scan: 0,
        bite_scan_1: 0,
        bite_scan_2: 0,
        substitute_document: 0,
        substitute_lower_scan: 0,
        substitute_bite_scan_1: 0,
        substitute_bite_scan_2: 0,
        scans_folder: 0,
        substitute_scans_folder: 0,
    });
    const [isReviewed, setIsReviewed] = useState(false);

    const handleSave = (items) => {
        setErrorMsg("");
        const form_data = new FormData();
        items.forEach((item) => {
            form_data.append(item.key, item.value);
        });
        form_data.append("procedure", appointmentType?.procedure);
        form_data.append("user_scan_info_id", appointmentId);
        form_data.append("id", imagePreview?.id);
        form_data.append("parent_id", imagePreview?.parent_id);
        axios.post(`/admin/v2/users/${userId}/case-record-scans`, form_data).then((res) => {
            if (res.data.success) {
                const data = res.data;
                handleDataFetched(data);
                setSuccess(true);
            }
            setDisabled(false);
        }).catch((err) => {
            setErrorMsg("Upload failed. Please try again.");
            setSuccess(false);
            setDisabled(false);
            console.log(err);
        });
    };

    const handleSaveSubstituteFiles = (items) => {
        setErrorMsg("");
        const form_data = new FormData();
        form_data.append("original_scan_id", imagePreview?.id);
        form_data.append("procedure", appointmentType?.procedure);
        form_data.append("user_scan_info_id", appointmentId);
        items.forEach((item) => {
            form_data.append(item.key, item.value);
        });
        axios.post(`/admin/v2/users/${userId}/case-record-substitute-scans`, form_data).then((res) => {
            if (res.data.success) {
                const data = res.data;
                handleDataFetched(data);
                setSuccess(true);
            }
            setDisabled(false);
        }).catch((err) => {
            setErrorMsg("Upload failed. Please try again.");
            setSuccess(false);
            setDisabled(false);
            console.log(err);
        });
    };

    const handleUploadError = (type, err) => {
        handleFileUpload(type, 0);
        setErrorMsg("Upload failed. Please try again.");
        setDisabled(false);
        console.log(err);
    };

    const zipScanFiles = async (arrayFiles, type) => {
        const zip = new JSZip();
        // Function to read a File and add it to the zip.
        const addFileToZip = (file) => {
            return new Promise(async (resolve) => {
                // Read the file as an ArrayBuffer.
                const fileData = await new Promise((resolveFile, rejectFile) => {
                    const reader = new FileReader();
                    reader.onload = () => resolveFile(reader.result);
                    reader.onerror = rejectFile;
                    reader.readAsArrayBuffer(file);
                });

                // Add the file to the zip archive with its original name.
                zip.file(file.name, fileData, {
                    compression: "DEFLATE",
                    compressionOptions: { level: 9 },
                });
                resolve();
            });
        };
        const promises = Array.from(arrayFiles).map(addFileToZip);
        try {
            await Promise.all(promises);
            const zipBlob = await zip.generateAsync({ type: "blob" });
            const form_data = new FormData();
            form_data.append("content_type", "text/plain");
            form_data.append("file_name", `user_${userId}_Scans-Folder.zip`);
            try {
                const response = await axios.post("/admin/v1/images/s3", form_data);
                if (response.status === 200) {
                    const { key, url } = response.data;
                    let items = [
                        {
                            key: type,
                            value: key,
                        },
                        {
                            key: `${type}_name`,
                            value: `user_${userId}_Scans-Folder.zip`,
                        },
                    ]
                    await axiosAws
                        .put(url, zipBlob, {
                            headers: {
                                "x-amz-acl": ["public-read-write"],
                                "Content-Type": "text/plain",
                            },
                        })
                        .then(() => {
                            if (type === "scans_folder") {
                                handleSave(items);
                            } else {
                                handleSaveSubstituteFiles(items);
                            }
                        });
                }
            } catch (error) {
                handleFileUpload(type, 0);
                console.log("Error in saving zip file on aws", error);
            }
        } catch (error) {
            handleFileUpload(type, 0);
            console.error("Error zipping files:", error);
        }
    };

    const handleFileUpload = (type, value) => {
        setUploadedFile(prev => ({
            ...prev,
            [type]: value,
        }));
    }

    const handleFolderUpload = (event, type) => {
        handleFileUpload(type, 1);
        const files = event.target.files;
        zipScanFiles(files, type);
    };

    const saveInAws = (value, type) => {
        handleFileUpload(type, 1);
        setErrorMsg("");
        let imageType = value.type;
        const form_data = new FormData();
        form_data.append("content_type", imageType);
        form_data.append("file_name", value.name);
        axios
            .post("/admin/v1/images/s3", form_data)
            .then((res) => {
                let items = [
                    {
                        key: type,
                        value: res.data.key,
                    },
                    {
                        key: type === "document" ? "name" : `${type}_name`,
                        value: value.name,
                    },
                ];
                const url2 = res.data.url;
                axiosAws
                    .put(url2, value, {
                        headers: {
                            "x-amz-acl": ["public-read-write"],
                            "Content-Type": imageType,
                        },
                        onUploadProgress: (event) => uploadConfig(event, type),
                    })
                    .then(() => {
                        if (
                            type === "document" ||
                            type === "lower_scan" ||
                            type === "bite_scan_1" ||
                            type === "bite_scan_2"
                        ) {
                            handleSave(items);
                        } else {
                            handleSaveSubstituteFiles(items);
                        }
                    })
                    .catch((err) => {
                        handleUploadError(type, err);
                    });
            })
            .catch((err) => {
                handleUploadError(type, err);
            });
    };

    const handleChange = (files, id) => {
        setDisabled(true);
        setErrorMsg("");
        setSuccess(false);
        const imageId = id;
        const file = files[0];
        saveInAws(file, imageId);
    };

    const uploadConfig = (progressEvent, name) => {
        if (progressEvent.loaded === progressEvent.total) {
            delete uploadProgress[name];
            setUploadProgress(uploadProgress);
        } else {
            const array = {
                ...uploadProgress,
                [name]: Math.round((progressEvent.loaded * 100) / progressEvent.total),
            };
            setUploadProgress(array);
        }
    };

    useEffect(() => {
        setIsReviewed(imagePreview?.review_status ? true : false);
        setSubstituteScan({
            document: imagePreview?.substitute_file?.scan_files?.find(
                (file) => file.name === "document"
            )
                ? true
                : false,
            lower_scan: imagePreview?.substitute_file?.scan_files?.find(
                (file) => file.name === "lower_scan"
            )
                ? true
                : false,
            bite_scan_1: imagePreview?.substitute_file?.scan_files?.find(
                (file) => file.name === "bite_scan_1"
            )
                ? true
                : false,
            bite_scan_2: imagePreview?.substitute_file?.scan_files?.find(
                (file) => file.name === "bite_scan_2"
            )
                ? true
                : false,
            scans_folder: imagePreview?.substitute_file?.scan_files?.find(
                (file) => file.name === "scans_folder"
            )
                ? true
                : false,
        });
    }, [imagePreview]);

    return (
        <div>
            {imagePreview?.id &&
                (scannerPlaceholders === 1 ? (
                    <div>
                        <Box className={classes.box}>
                            <ScansDropZone
                                uploadProgress={uploadProgress?.scans_folder}
                                disable={isReviewed || disable || disabled}
                                value={imageValueV2(
                                    uploadedFile.scans_folder ? uploadedFile.scans_folder :
                                    imagePreview?.scan_files.find(
                                        (scanFile) => scanFile.name === "scans_folder"
                                    )?.file_url
                                )}
                                id={"scans_folder"}
                                handleChange={handleFolderUpload}
                                message={t("upload-scans-folder")}
                                name={
                                    imagePreview?.scan_files.find(
                                        (scanFile) => scanFile.name === "scans_folder"
                                    )?.file_name
                                }
                                folderUpload
                            />
                            {substituteScan.scans_folder && (
                                <ScansDropZone
                                    uploadProgress={uploadProgress?.substitute_scans_folder}
                                    disable={isReviewed || disable || disabled}
                                    value={imageValueV2(
                                        uploadedFile.substitute_scans_folder ? uploadedFile.substitute_scans_folder :
                                        imagePreview?.substitute_file?.scan_files.find(
                                            (scanFile) => scanFile.name === "scans_folder"
                                        )?.file_url
                                    )}
                                    id={"substitute_scans_folder"}
                                    handleChange={handleFolderUpload}
                                    message={t("upload-scans-folder")}
                                    name={
                                        imagePreview?.substitute_file?.scan_files.find(
                                            (scanFile) => scanFile.name === "scans_folder"
                                        )?.file_name
                                    }
                                    folderUpload
                                />
                            )}
                        </Box>
                    </div>
                ) : (
                    <div>
                        <Box className={classes.box}>
                            <ScansDropZone
                                uploadProgress={uploadProgress?.document}
                                disable={isReviewed || disable || disabled}
                                value={imageValueV2(
                                    uploadedFile.document ? uploadedFile.document :
                                    imagePreview?.scan_files.find(
                                        (scanFile) => scanFile.name === "document"
                                    )?.file_url
                                )}
                                id={"document"}
                                handleChange={handleChange}
                                message={t("Upload-upper-scan")}
                                name={
                                    imagePreview?.scan_files.find(
                                        (scanFile) => scanFile.name === "document"
                                    )?.file_name
                                }
                            />
                            <ScansDropZone
                                uploadProgress={uploadProgress?.lower_scan}
                                disable={isReviewed || disable || disabled}
                                value={imageValueV2(
                                    uploadedFile.lower_scan ? uploadedFile.lower_scan :
                                    imagePreview?.scan_files.find(
                                        (scanFile) => scanFile.name === "lower_scan"
                                    )?.file_url
                                )}
                                id={"lower_scan"}
                                handleChange={handleChange}
                                message={t("Upload-lower-scan")}
                                name={
                                    imagePreview?.scan_files.find(
                                        (scanFile) => scanFile.name === "lower_scan"
                                    )?.file_name
                                }
                            />
                        </Box>
                        <Box className={classes.box}>
                            <ScansDropZone
                                uploadProgress={uploadProgress?.bite_scan_1}
                                disable={isReviewed || disable || disabled}
                                value={imageValueV2(
                                    uploadedFile.bite_scan_1 ? uploadedFile.bite_scan_1 :
                                    imagePreview?.scan_files.find(
                                        (scanFile) => scanFile.name === "bite_scan_1"
                                    )?.file_url
                                )}
                                id={"bite_scan_1"}
                                handleChange={handleChange}
                                message={t("Bite-scan-1")}
                                name={
                                    imagePreview?.scan_files.find(
                                        (scanFile) => scanFile.name === "bite_scan_1"
                                    )?.file_name
                                }
                            />
                            <ScansDropZone
                                uploadProgress={uploadProgress?.bite_scan_2}
                                disable={isReviewed || disable || disabled}
                                value={imageValueV2(
                                    uploadedFile.bite_scan_2 ? uploadedFile.bite_scan_2 :
                                    imagePreview?.scan_files.find(
                                        (scanFile) => scanFile.name === "bite_scan_2"
                                    )?.file_url
                                )}
                                id={"bite_scan_2"}
                                handleChange={handleChange}
                                message={t("Bite-scan-2")}
                                name={
                                    imagePreview?.scan_files.find(
                                        (scanFile) => scanFile.name === "bite_scan_2"
                                    )?.file_name
                                }
                            />
                        </Box>
                        {(substituteScan.document ||
                            substituteScan.lower_scan ||
                            substituteScan.bite_scan_1 ||
                            substituteScan.bite_scan_2) && (
                                <div>
                                    <Box className={classes.substituteTitleBox}>
                                        <Typography
                                            className={`${classes.titleStyle} ${i18n.language === "ar"
                                                    ? classes.fontFamilyAlmarai
                                                    : classes.fontFamilyEina
                                                }`}
                                        >
                                            {t("substituted-scans")}
                                        </Typography>
                                    </Box>
                                    {(substituteScan.document || substituteScan.lower_scan) && (
                                        <Box className={classes.box}>
                                            {substituteScan.document && (
                                                <ScansDropZone
                                                    uploadProgress={uploadProgress?.substitute_document}
                                                    disable={isReviewed || disable || disabled}
                                                    value={imageValueV2(
                                                        uploadedFile.substitute_document ? uploadedFile.substitute_document :
                                                        imagePreview?.substitute_file?.scan_files.find(
                                                            (scanFile) => scanFile.name === "document"
                                                        )?.file_url
                                                    )}
                                                    id={"substitute_document"}
                                                    handleChange={handleChange}
                                                    message={t("Upload-upper-scan")}
                                                    name={
                                                        imagePreview?.substitute_file?.scan_files.find(
                                                            (scanFile) => scanFile.name === "document"
                                                        )?.file_name
                                                    }
                                                />
                                            )}
                                            {substituteScan.lower_scan && (
                                                <ScansDropZone
                                                    uploadProgress={uploadProgress?.substitute_lower_scan}
                                                    disable={isReviewed || disable || disabled}
                                                    value={imageValueV2(
                                                        uploadedFile.substitute_lower_scan ? uploadedFile.substitute_lower_scan :
                                                        imagePreview?.substitute_file?.scan_files.find(
                                                            (scanFile) => scanFile.name === "lower_scan"
                                                        )?.file_url
                                                    )}
                                                    id={"substitute_lower_scan"}
                                                    handleChange={handleChange}
                                                    message={t("Upload-lower-scan")}
                                                    name={
                                                        imagePreview?.substitute_file?.scan_files.find(
                                                            (scanFile) => scanFile.name === "lower_scan"
                                                        )?.file_name
                                                    }
                                                />
                                            )}
                                        </Box>
                                    )}
                                    {(substituteScan.bite_scan_1 || substituteScan.bite_scan_2) && (
                                        <Box className={classes.box}>
                                            {substituteScan.bite_scan_1 && (
                                                <ScansDropZone
                                                    uploadProgress={uploadProgress?.substitute_bite_scan_1}
                                                    disable={isReviewed || disable || disabled}
                                                    value={imageValueV2(
                                                        uploadedFile.substitute_bite_scan_1 ? uploadedFile.substitute_bite_scan_1 :
                                                        imagePreview?.substitute_file?.scan_files.find(
                                                            (scanFile) => scanFile.name === "bite_scan_1"
                                                        )?.file_url
                                                    )}
                                                    id={"substitute_bite_scan_1"}
                                                    handleChange={handleChange}
                                                    message={t("Bite-scan-1")}
                                                    name={
                                                        imagePreview?.substitute_file?.scan_files.find(
                                                            (scanFile) => scanFile.name === "bite_scan_1"
                                                        )?.file_name
                                                    }
                                                />
                                            )}
                                            {substituteScan.bite_scan_2 && (
                                                <ScansDropZone
                                                    uploadProgress={uploadProgress?.substitute_bite_scan_2}
                                                    disable={isReviewed || disable || disabled}
                                                    value={imageValueV2(
                                                        uploadedFile.substitute_bite_scan_2 ? uploadedFile.substitute_bite_scan_2 :
                                                        imagePreview?.substitute_file?.scan_files.find(
                                                            (scanFile) => scanFile.name === "bite_scan_2"
                                                        )?.file_url
                                                    )}
                                                    id={"substitute_bite_scan_2"}
                                                    handleChange={handleChange}
                                                    message={t("Bite-scan-2")}
                                                    name={
                                                        imagePreview?.substitute_file?.scan_files.find(
                                                            (scanFile) => scanFile.name === "bite_scan_2"
                                                        )?.file_name
                                                    }
                                                />
                                            )}
                                        </Box>
                                    )}
                                </div>
                            )}
                    </div>
                ))}
            {imagePreview?.replaced_file && (
                <>
                    <Box className={classes.replacedTitleBox}>
                        <Typography
                            className={`${classes.titleStyle} ${i18n.language === "ar"
                                    ? classes.fontFamilyAlmarai
                                    : classes.fontFamilyEina
                                }`}
                        >
                            {t("replaced-scans")}
                        </Typography>
                    </Box>
                    <ScanFiles
                        imagePreview={imagePreview.replaced_file}
                        appointmentType={appointmentType}
                        disable={disable}
                        setSuccess={setSuccess}
                        appointmentId={appointmentId}
                        handleDataFetched={handleDataFetched}
                        userId={userId}
                        classes={classes}
                        setErrorMsg={setErrorMsg}
                        scannerPlaceholders={scannerPlaceholders}
                        t={t}
                    />
                </>
            )}
        </div>
    );
};

export default ScanFiles;
