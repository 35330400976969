import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Menu,
  MenuItem,
  makeStyles,
  Typography,
  Box,
  Select,
  FormControl,
  CircularProgress,
  Tooltip,
  Badge,
} from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import PropTypes from "prop-types";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import React, { Fragment, useEffect, useState } from "react";
import lodashGet from "lodash/get";
import EnhancedTableHead from "./EnhancedTableHead";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import PersonIcon from "@mui/icons-material/Person";
import axios from "../../util/axios";
import ConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog";
import Edit from "../Note/EditNote";
import IconCheckView from "../IconCheckView";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { useTranslation } from "react-i18next";
import {
  clinicImpersonated,
  convertNumberToArabic,
  convertToNumber,
  iconColors,
} from "../../util/functions";
import InboxIcon from "@mui/icons-material/Inbox";
import DoneIcon from "@mui/icons-material/Done";
import Cookies from "js-cookie";
import { Modal } from "@mui/material";
import { TableActionsConstants, TableAppointmentsStatues } from "../../util/constants/table-actions.constants";
import AppointmentStatus from "../AppointmentStatus/appointment-status.component";
import moment from "moment";
import { GeneralFormats } from "../../util/constants";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

EnhancedTable.propTypes = {
  rowsData: PropTypes.array.isRequired,
  handleSearch: PropTypes.func,
  headCells: PropTypes.array.isRequired,
  menu: PropTypes.array,
  // defaultOrder: PropTypes.string,
  // defaultOrientation: PropTypes.string,
};
EnhancedTable.defaultProps = {
  // defaultOrder: '',
  // defaultOrientation: 'desc',
  rowsData: [],
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  tableHead: {
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    "::-webkit-scrollbar": {
      width: 0,
      height: 0,
    },
    fontWeight: 600,
  },
  table: {
    minWidth: 750,
    borderCollapse: "separate",
    borderSpacing: "0 15px",
  },
  button: {
    maxWidth: "100%",
    color: "white",
    justifyContent: "center",
    background: theme.palette.primary.main,
    "&:hover": {
      background: "white",
      color: theme.palette.primary.main,
    },
  },
  loadingBox: {
    margin: "0 auto",
    textAlign: "center",
    padding: 30,
  },
  loading: {
    textAlign: "center",
    margin: "0 auto",
    color: "#ff2c46",
  },
  searchBar: {
    width: "100%",
    paddingLeft: 10,
    height: 40,
    border: "1px black solid",
    "&::placeholder": {
      fontWeight: 400,
    },
  },
  horizontalMenu: {
    transform: "scale(1.4)",
    color: "gray",
  },
  select: {
    border: "none transparent",
  },
  arrowButton: {
    backgroundColor: "white",
    border: "none",
    transform: "scale(0.6)",
    "&:hover": {
      color: "#FF2C46",
      cursor: "pointer",
    },
  },
  paginateWrapper: {
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    gap: 10,
    justifyContent: "flex-end",
    padding: 20,
  },
  buttonWrapper: {
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    opacity: 0.6,
    fontSize: "10px",
  },
  iconTextWrapper: {
    display: 'flex',
    columnGap: 5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  phonenumberCase: {
    fontSize: "0.7rem!important",
    color: "#404040",
    opacity: "0.7",
  },
  phoneNbAr: {
    direction: "initial",
  },
  tableRow: {
    width: 150,
    textAlign: "center",
    padding: 5,
  },
  filter: {
    padding: 5,
  },
  menuWrapper: {
    padding: 10,
    margin: "0 auto",
    width: "100%",
    textAlign: "center",
  },
  menu: {
    padding: "20px 0 !important",
    textAlign: "center",
  },
  noAppointmentWrapper: {
    textAlign: "center",
    margin: "0 auto",
    display: "grid",
    gap: 30,
    paddingTop: 50,
  },
  noAppointmentsTypo: {
    fontWeight: "bold",
  },
  noAppointment: {
    transform: "scale(3)",
    textAlign: "center",
    margin: "0 auto",
    color: "#ff2c46",
  },
  timeCell: {
    display: "flex",
    alignContent: "center",
    gap: 10,
  },
  statusStyle: {
    display: "flex",
    justifyContent: "center",
  },
  productWrapper: {
    display: "flex",
    minWidth: "170px",
    columnGap: "10px",
  },
  productSelect: {
    flex: 1,
    fontSize: "12px",
  },
  saveBtn: {
    width: "fit-content",
    padding: "4px",
    minWidth: "unset",
  },
  pending: {
    color: "#fff",
    backgroundColor: "#87abff",
    height: 20,
  },
  completed: {
    color: "#fff",
    backgroundColor: "#98bae7",
    height: 20,
  },
  confirmed: {
    color: "#fff",
    backgroundColor: "#55BB8C",
    height: 20,
  },
  qualified: {
    color: "#fff",
    backgroundColor: "#8ee2ed",
    height: 20,
  },
  cancelled: {
    color: "#fff",
    backgroundColor: "red",
    height: 20,
  },
  noShowUp: {
    color: "#fff",
    backgroundColor: "#FFA500",
    height: 20,
  },
  noAnswer: {
    color: "#fff",
    backgroundColor: "#FFA500",
    height: 20,
  },
  firstCall: {
    color: "#fff",
    backgroundColor: "#87abff",
    height: 20,
  },
  secondCall: {
    color: "#fff",
    backgroundColor: "#87abff",
    height: 20,
  },
  wtspSent: {
    color: "#fff",
    backgroundColor: "#87abff",
    height: 20,
  },
  unScheduled: {
    color: "#fff",
    backgroundColor: "#6297E8",
    height: 20,
  },
  unqualified: {
    color: "#fff",
    backgroundColor: "#65737E",
    height: 20,
  },
  completedUnQualified: {
    color: "#fff",
    backgroundColor: "#C5B7CD",
    height: 20,
  },
  // unscheduledBooking: {
  //     color: '#fff',
  //     backgroundColor: '#6297E8',
  //     height: 20,
  // },
  postponed: {
    color: "#fff",
    backgroundColor: "#f95518",
    height: 20,
  },
  addNote: {
    width: 170,
    textAlign: "center",
    marginTop: 10,
    boxSizing: " border-box",
    border: "1px solid #C4CDD5",
    borderRadius: "3px",
    background: " linear-gradient(180deg, #FFFFFF 0%, #F9FAFB 100%)",
    boxShadow: " 0 1px 0 0 rgba(22,29,37,0.05)",
    marginBottom: 10,
  },
  photos: {
    fontWeight: "bold",
    textAlign: "center",
    transform: "translateX(-12%)",
  },
  note: {
    verticalAlign: "bottom",
    marginLeft: "3px",
    backgroundColor: "#ccc",
    borderRadius: "20px",
    cursor: "pointer",
    width: "1.5em",
  },
  typeIcon: {
    color: "#fff",
    backgroundColor: "#C5B7CD",
    fontSize: "0.5rem !important",
    padding: "0.32rem 0.1rem 0.2rem 0.1rem",
    borderRadius: "10rem",
    marginBottom: "0.2rem",
    width: "5rem",
    ["@media(max-width: 1434px)"]: {
      marginLeft: "0.3rem",
    },
  },
  typeTableCell: {
    display: "grid",
    margin: "auto",
    width: "70%",
    ["@media(max-width: 1479px)"]: {
      width: "100%",
    },
    ["@media(max-width: 1280px)"]: {
      gridTemplateColumns: "1fr !important",
    },
  },
  padding: {
    padding: "12px",
  },
  tagIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    width: "6rem",
    margin: "0 auto",
  },
  nameWrapper: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },
  archiveIcon: {
    fontSize: "1rem !important",
    color: "#8E8E8E",
  },
  nameDesign: {
    color: "#4d9cff",
    textDecoration: "none",
    cursor: "pointer",
  },
  scheduleBefore: {
    margin: "auto",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
}));
export function EnhancedTableCell(props) {
  const {
    headCell,
    row,
    menuItems,
    setOpenNote,
    products = [],
    isScanAtHome = false,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const [resize, setResize] = useState(false);
  const size = window.matchMedia("(max-width: 1280px)");
  const [expandType, setExpandType] = useState(size.matches);
  const tableAppointmentsStatues = TableAppointmentsStatues;
  const [selectedProduct, setSelectedProduct] = useState(
    isScanAtHome
      ? !headCell.hideRow && headCell.id === "payment_type"
        ? row["scan_at_home_details"][headCell.id]
        : null
      : null
  );
  const [isDisabled, setIsDisabled] = useState(false);
  const handleSelectedProduct = () => {
    if (selectedProduct !== "-") {
      setIsDisabled(true);
      let body = {
        user_id: row.user_id,
        clinic_product_id: selectedProduct,
      };
      axios
        .post(`/admin/v1/clinic/${Cookies.get("clinic_id")}/scan-at-home`, body)
        .then((res) => {
          window.location.reload(false);
        })
        .catch((err) => {
          console.log("scan at home err", err);
        })
        .finally((f) => {
          setIsDisabled(false);
        });
    }
  };

  useEffect(() => {
    if (isScanAtHome) {
      if (!row?.user_action.find((action) => action?.action == "Reschedule")) {
        if (
          selectedProduct == "-" ||
          row.scan_at_home_details.payment_status === "Paid"
        ) {
          setIsDisabled(true);
        } else {
          if (selectedProduct) {
            setIsDisabled(false);
          }
        }
      } else {
        setIsDisabled(false);
      }
    }
  }, [isScanAtHome, selectedProduct]);

  useEffect(() => {
    const handler = (e) => setExpandType(e.matches);
    size.addEventListener("change", handler);
    return () => size.removeEventListener("change", handler);
  }, []);

  const [open, setOpen] = React.useState(false);
  const [comment, setComment] = React.useState('');
  const handleOpen = (c) => {
    setOpen(true);
    setComment(c);
  }
  const handleClose = () => setOpen(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #535353",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
  };

  const handleStatus = () => {
    if (
      (row[headCell.id] === "Completed" || row[headCell.id] === "Cancelled") &&
      row?.user_status &&
      row.user_status == "User Not Qualified"
    ) {
      return (
        <AppointmentStatus
          label={t("completed-unqualified")}
          classes={classes}
          setOpenNote={setOpenNote}
          chipClass={classes.completedUnQualified}
          row={row}
        />
      );
    } else if (row[headCell.id] === "Completed" || row[headCell.id] === tableAppointmentsStatues.APPOINTMENT_DONE_ELSEWHERE) {
      return (
        <AppointmentStatus
          label={t(row[headCell.id])}
          classes={classes}
          setOpenNote={setOpenNote}
          chipClass={classes.completed}
          row={row}
        />
      );
    } else if (row[headCell.id] === "Pending" || row[headCell.id] === tableAppointmentsStatues.NEEDS_RESCHEDULING) {
      return (
          <AppointmentStatus
              label={t(row[headCell.id])}
              classes={classes}
              setOpenNote={setOpenNote}
              chipClass={classes.pending}
              row={row}
          />
      );
    } else if (row[headCell.id] === "Confirmed") {
      return (
        <AppointmentStatus
          label={t(row[headCell.id])}
          classes={classes}
          setOpenNote={setOpenNote}
          chipClass={classes.confirmed}
          row={row}
        />
      );
    } else if (row[headCell.id] === "Qualified") {
      return (
        <AppointmentStatus
          label={t(row[headCell.id])}
          classes={classes}
          setOpenNote={setOpenNote}
          chipClass={classes.qualified}
          row={row}
        />
      );
    } else if (row[headCell.id] === "Cancelled") {
      return (
        <AppointmentStatus
          label={t(row[headCell.id])}
          classes={classes}
          setOpenNote={setOpenNote}
          chipClass={classes.cancelled}
          row={row}
        />
      );
    } else if (row[headCell.id] === "No Show") {
      return (
        <AppointmentStatus
          label={t(row[headCell.id])}
          classes={classes}
          setOpenNote={setOpenNote}
          chipClass={classes.noShowUp}
          row={row}
        />
      );
    } else if (row[headCell.id] === "No Answer") {
      return (
        <AppointmentStatus
          label={t(row[headCell.id])}
          classes={classes}
          setOpenNote={setOpenNote}
          chipClass={classes.noAnswer}
          row={row}
        />
      );
    } else if (row[headCell.id] === tableAppointmentsStatues.FIRST_CALL_ATTEMPTED) {
      return (
        <AppointmentStatus
          label={t('Pending-(First Call Attempted)')}
          classes={classes}
          setOpenNote={setOpenNote}
          chipClass={classes.firstCall}
          row={row}
        />
      );
    } else if (row[headCell.id] === tableAppointmentsStatues.SECOND_CALL_ATTEMPTED) {
      return (
        <AppointmentStatus
          label={t('Pending-(Second Call Attempted)')}
          classes={classes}
          setOpenNote={setOpenNote}
          chipClass={classes.secondCall}
          row={row}
        />
      );
    } else if (row[headCell.id] === tableAppointmentsStatues.WHATSAPP_SENT) {
      return (
        <AppointmentStatus
          label={t('Pending-(WhatsApp Sent)')}
          classes={classes}
          setOpenNote={setOpenNote}
          chipClass={classes.wtspSent}
          row={row}
        />
      );
    } else if (row[headCell.id] === "Unqualified") {
      return (
        <AppointmentStatus
          label={t(row[headCell.id])}
          classes={classes}
          setOpenNote={setOpenNote}
          chipClass={classes.unqualified}
          row={row}
        />
      );
    } else if (row[headCell.id] === "Unscheduled") {
      return (
        <AppointmentStatus
          label={t(row[headCell.id])}
          classes={classes}
          setOpenNote={setOpenNote}
          chipClass={classes.unScheduled}
          row={row}
        />
      );
    } else if (row[headCell.id] === "Postponed") {
      return (
        <AppointmentStatus
          label={t(row[headCell.id])}
          classes={classes}
          setOpenNote={setOpenNote}
          chipClass={classes.postponed}
          row={row}
        />
      );
    } else if (!headCell.hideRow && headCell.id === "payment_status") {
      return row["scan_at_home_details"][headCell.id];
    } else if (!headCell.hideRow && headCell.id === "payment_type") {
      return (
        <Box className={classes.productWrapper}>
          <Select
            placeholder="Please fill"
            className={classes.productSelect}
            value={selectedProduct}
            onChange={(e) => setSelectedProduct(e.target.value)}
            disabled={
              row?.user_action.find((action) => action?.action == "Reschedule")
                ? false
                : row.scan_at_home_details.payment_status === "Paid"
            }
          >
            {products.map((prod, key) => {
              return (
                <MenuItem key={key} value={prod.value}>
                  {prod.label}
                </MenuItem>
              );
            })}
          </Select>
          <Button
            disabled={isDisabled}
            onClick={handleSelectedProduct}
            className={classes.saveBtn}
          >
            <DoneIcon style={{ fontSize: "15px", fontWeight: "bold" }} />
          </Button>
        </Box>
      );
    } else if (headCell.id === "user_step" && row["position"]) {
      return (
        <Tooltip
          style={{ cursor: "pointer" }}
          arrow
          title={t('procedure-count', {nb: convertToNumber(row["position"]), total: row["number_of_steps"]})}
          placement="top"
        >
          <Box className={classes.scheduleBefore}>
            {`${row['planType'] ?? ''} ${row["user_step"] == 0 ? "S1" : row["user_step"]}${
              row["position"]
            }`}
          </Box>
        </Tooltip>
      );
    } else {
      return headCell.id === "user_step" && row[headCell.id] == 0
        ? `${row['planType'] ?? ''}S1`
        : `${row['planType'] ?? ''} ${row[headCell.id] ?? "-"}`;
    }
  };
  if (headCell.type === "menu") {
    return (
      <TableCell
        align="center"
        className={`${classes.tableRow} ${classes.padding}`}
      >
        <TableMenu row={row} menuItems={menuItems} />
      </TableCell>
    );
  } else if (headCell.type === "phone") {
    return (
      <TableCell
        align={lodashGet(headCell, "align", "center")}
        component="th"
        scope="row"
        className={`${classes.padding} ${classes.phoneNbAr}`}
      >
        <div className={classes.iconTextWrapper}>
          <LocalPhoneIcon className={classes.phonenumberCase} />
          <Typography className={classes.phonenumberCase}>
            {t(row[headCell.id])}
          </Typography>
        </div>
        {
          row?.whatsapp_number &&
          <div className={classes.iconTextWrapper}>
            <WhatsAppIcon className={classes.phonenumberCase} />
            <Typography className={classes.phonenumberCase}>
              {row.whatsapp_number}
            </Typography>
          </div>
        }
      </TableCell>
    );
  } else if (headCell.type === "time") {
    return (
      <TableCell
        alignLeft
        component="th"
        scope="row"
        className={classes.padding}
      >
        <Tooltip
          arrow
          title={
            row[headCell.preferredDate] != null ? (
              <div>
                {headCell.preferredDate == "preferred_date" &&
                row[headCell.preferredDate] != null ? (
                  <p style={{ fontSize: "0.7rem" }}>
                    Preferred Date: {row[headCell.preferredDate]}
                  </p>
                ) : (
                  ""
                )}
                {headCell.preferredTime == "preferred_time" &&
                row[headCell.preferredTime] != null ? (
                  <p style={{ fontSize: "0.7rem" }}>
                    Preferred Time: {row[headCell.preferredTime]}
                  </p>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )
          }
          placement="top"
        >
          <div>
            <Typography className={classes.photos}>
              { row?.user_sub_status === tableAppointmentsStatues.APPOINTMENT_DONE_ELSEWHERE ? '-' : t(row[headCell.id])}
            </Typography>
          </div>
        </Tooltip>
      </TableCell>
    );
  } else if (headCell.type === "booking_date") {
    return (
      <TableCell
        alignLeft
        component="th"
        scope="row"
        className={classes.padding}
      >
        {/* <div> */}
        <Typography className={classes.photos}>
          {t(row[headCell.id])}
        </Typography>
        {/* </div> */}
      </TableCell>
    );
  } else if (headCell.type === "photos") {
    return (
      <TableCell
        component="th"
        scope="row"
        className={classes.padding}
        style={{ textAlign: "center" }}
      >
        <IconCheckView value={row[headCell.id]} />
      </TableCell>
    );
  } else if (headCell.type === "questions") {
    return (
      <TableCell
        component="th"
        scope="row"
        className={classes.padding}
        style={{ textAlign: "center" }}
      >
        <IconCheckView value={row[headCell.id]} />
      </TableCell>
    );
  } else if (headCell.type === "scans") {
    return (
      <TableCell
        alignLeft
        component="th"
        scope="row"
        className={classes.padding}
        style={{ textAlign: "center" }}
      >
        <IconCheckView value={row[headCell.id]} />
      </TableCell>
    );
  } else if (headCell.type === "status") {
    return (
      <TableCell
        alignLeft
        component="th"
        scope="row"
        className={classes.padding}
      >
        <Typography className={classes.timeCell}>
          <AccessTimeIcon />
          {t(row[headCell.id])}
        </Typography>
      </TableCell>
    );
  } else if (headCell.type === "appointment_name") {
    return (
      <TableCell
        align={lodashGet(headCell, "align", "center")}
        width={headCell.from === "appointments" ? "14%" : ""}
        component="th"
        scope="row"
        className={classes.padding}
      >
        <Tooltip
          arrow
          title={
            row[headCell.preferredDate] != null ? (
              <div>
                {headCell.preferredDate == "preferred_date" &&
                row[headCell.preferredDate] != null ? (
                  <p style={{ fontSize: "0.7rem" }}>
                    Preferred Date: {row[headCell.preferredDate]}
                  </p>
                ) : (
                  ""
                )}
                {headCell.preferredTime == "preferred_time" &&
                row[headCell.preferredTime] != null ? (
                  <p style={{ fontSize: "0.7rem" }}>
                    Preferred Time: {row[headCell.preferredTime]}
                  </p>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )
          }
          placement="top"
        >
          <Box className={classes.nameWrapper}>
            {row?.is_archived == 1 && headCell.from == "appointments" && (
              <InboxIcon className={classes.archiveIcon} />
            )}
            <a
              href={`/dashboard/users/${row.user_id}`}
              className={classes.nameDesign}
            >
              <p style={{fontFamily: (row?.std_first_name_en || row?.std_name_en) ? 'Almarai-semi' : 'Eina-semi'}}>{t(row[headCell.id])}</p>
              {(row?.std_first_name_en || row?.std_name_en) ? (
                <div>
                  <p style={{fontSize: '12px', color: '#797979'}}>
                    {headCell.id === "first_name"
                      ? row?.std_first_name_en || row?.std_name_en || ""
                      : row?.std_last_name_en || row?.std_name_en || ""}
                  </p>
                </div>
              ) : (
                ""
              )}
            </a>
          </Box>
        </Tooltip>
      </TableCell>
    );
  } else if (headCell.type === "type") {
    const visible =
      expandType &&
      !resize &&
      row[headCell.id] != null &&
      row[headCell.id].length > 1;
    const nbTypeVisibility =
      row[headCell.id] != null
        ? expandType
          ? resize
            ? row[headCell?.id]?.length
            : 1
          : row[headCell.id].length
        : null;
    const typesArray = row[headCell.id];
    return (
      props && (
        <TableCell
          align={lodashGet(headCell, "align", "center")}
          onClick={() => setResize((prev) => !prev)}
          className={classes.padding}
        >
          <Box
            style={{
              gridTemplateColumns:
                row[headCell.id] != null && row[headCell.id].length > 1
                  ? "1fr 1fr"
                  : "1fr",
              position: "relative",
            }}
            className={classes.typeTableCell}
          >
            {typesArray &&
              typesArray?.slice(0, nbTypeVisibility)?.map((res, key) => (
                <Box key={key} className={classes.tagIcon}>
                  <div style={{ display: "flex" }}>
                    <Typography
                      className={classes.typeIcon}
                      style={{
                        backgroundColor:
                          iconColors[res]?.background ?? "#3CC7D3",
                        color: iconColors[res]?.color ?? "black",
                      }}
                    >
                      {t(res)}
                    </Typography>
                    {row.proceduresComments &&  row.proceduresComments[res] ? (
                      <>
                        <Modal
                          open={open}
                          onClose={handleClose}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box sx={style}>
                            {comment}
                          </Box>
                        </Modal>
                        <Tooltip
                          style={{ cursor: "pointer" }}
                          arrow
                          title={`Comment: ${row.proceduresComments[res]}`}
                          placement="top"
                        >
                          <Badge variant="standard" color="primary">
                            <img
                              src="/assets/images/info_icon.svg"
                              style={{
                                width: "18px",
                                height: "18px",
                                marginLeft: "3px",
                              }}
                              onClick={() => {
                                handleOpen(row.proceduresComments[res]);
                              }}
                            />
                          </Badge>
                        </Tooltip>
                      </>
                    ) : ''}
                  </div>
                  {visible && (
                    <Typography
                      style={{ marginLeft: "0.3rem", transform: "scale(0.7)" }}
                    >
                      +{typesArray.length - 1}
                    </Typography>
                  )}
                </Box>
              ))}
          </Box>
        </TableCell>
      )
    );
  } else if (headCell.type === "schedule") {
    return (
      <TableCell
        align={lodashGet(headCell, "align", "center")}
        component="div"
        scope="row"
        style={{ color: headCell.color ? headCell.color : "initial" }}
        className={classes.padding}
      >
        <Tooltip
          arrow
          title={`${row?.warning_appointment ? t("book-now") : ""}`}
          placement="top"
        >
          <Box className={classes.scheduleBefore}>
            {row[headCell.id] ?? "-"}
            {row.warning_appointment && (
              <img
                style={{ width: "16px", height: "16px" }}
                src={"/assets/images/warning-icon.png"}
              />
            )}
          </Box>
        </Tooltip>
      </TableCell>
    );
  } else {
    return (
      <TableCell
        align={lodashGet(headCell, "align", "center")}
        component="th"
        scope="row"
        style={{ color: headCell.color ? headCell.color : "initial" }}
        className={classes.padding}
      >
        {handleStatus()}
      </TableCell>
    );
  }
}
// export function defaultComparator(a, b, order) {
//     const isDesc = order === 'desc'
//     if (b < a) {
//         return isDesc ? -1 : 1
//     }
//     if (b > a) {
//         return isDesc ? 1 : -1
//     }
//     return 0
// }

// export function dateComparator(a, b, order) {
//     const isDesc = order === 'desc'
//     const dateA = new Date(a)
//     const dateB = new Date(b)
//     if (dateA < dateB) {
//         return isDesc ? -1 : 1
//     }
//     if (dateA > dateB) {
//         return isDesc ? 1 : -1
//     }
//     return 0
// }

// export function timeComparator(a, b, order) {
//     const isDesc = order === 'desc'
//     const dateNow = new Date()
//     const dateString = dateNow.toDateString()
//     const dateA = new Date(`${dateString} ${a}`)
//     const dateB = new Date(`${dateString} ${b}`)

//     if (dateA < dateB) {
//         return isDesc ? -1 : 1
//     }
//     if (dateA > dateB) {
//         return isDesc ? 1 : -1
//     }
//     return 0
// }
// function stableSort(array, order, orderBy, comparator) {
//     const rowSorter = array.map((value, index) => ({ value, index }))
//     rowSorter.sort((a, b) => {
//         const sorter = comparator(a.value[orderBy], b.value[orderBy], order)
//         return sorter !== 0 ? sorter : a.index - b.index
//     })
//     return rowSorter.map((el) => el.value)
// }
export function TableSection(props) {
  const {
    rows,
    headCells,
    setField,
    setSort,
    ipr,
    classes,
    menu,
    // defaultOrder,
    // defaultOrientation,
    setOpenNote,
    products = [],
    isScanAtHome = false,
    handleOpenDialog,
    handleOpenScheduleDialog,
    handleOpenLostDialog,
    // handleTableLoading,
    sort,
    loading,
    updatePatientActions,
  } = props;
  // const [order, setOrder] = React.useState(defaultOrientation)
  // const [orderBy, setOrderBy] = React.useState(defaultOrder)
  const [message, setMessage] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [count, setCount] = useState(true);
  const { t } = useTranslation();
  const tableActions = TableActionsConstants;

  // const handleRequestSort = (property) => {
  //     const isAsc = orderBy === property && order === 'asc'
  //     setOrder(isAsc ? 'desc' : 'asc')
  //     setSort(isAsc ? 'desc' : 'asc')
  //     setOrderBy(property)
  //     setField(property)
  // }
  // const orderByHeadCell = lodashFind(headCells, (headCell) => headCell.id === orderBy)
  // const cellType = lodashGet(orderByHeadCell, 'type', '')
  // const comparator = lodashGet(
  //     orderByHeadCell,
  //     'comparator',
  //     (cellType === 'date' && dateComparator) ||
  //         (cellType === 'time' && timeComparator) ||
  //         defaultComparator
  // )
  // let sortedRows = stableSort(rows, order, orderBy, comparator)

  return (
    <TableContainer className={classes.tableHead}>
      {loading ? (
        <Box className={classes.loadingBox}>
          <CircularProgress className={classes.loading} />
        </Box>
      ) : (
        <Table className={classes.table}>
          <EnhancedTableHead
            // order={order}
            // orderBy={orderBy}
            sort={sort}
            setSort={setSort}
            setField={setField}
            // handleRequestSort={handleRequestSort}
            headCells={headCells}
            setOpenNote={setOpenNote}
          />
          <TableBody>
            {rows?.map((row, index) => {
              const dateTimeFormat = GeneralFormats.dateTimeFormat;
              const menuAction = row?.user_action?.map((value, index) => {
                let date = value?.date ? moment(value?.date).format(dateTimeFormat) : null;
                if (
                  ipr &&
                  value?.action !== "Completed" &&
                  value?.action !== "No Answer" &&
                  value?.action !== "Cancel" &&
                  value?.action !== tableActions.DONE_ELSEWHERE &&
                  value?.action !== tableActions.FIRST_CALL_ATTEMPTED &&
                  value?.action !== tableActions.SECOND_CALL_ATTEMPTED &&
                  value?.action !== tableActions.WHATSAPP_SENT
                ) {
                  return {
                    id: value?.action,
                    action: (row) => handleOpenScheduleDialog(row),
                    disabled: value?.disabled,
                    date,
                  };
                } else if (value?.action == "Reschedule") {
                  return {
                    id: value?.action,
                    action: (row) => handleOpenDialog(row),
                    disabled: value?.disabled,
                    date,
                  };
                } else if (value?.action == "Schedule") {
                  let item = {
                    id: "Schedule",
                    action: (row) => handleOpenScheduleDialog(row),
                    disabled: value?.disabled,
                    date,
                  };
                  if (
                    isScanAtHome &&
                    row.scan_at_home_details.payment_status != "Paid"
                  ) {
                    item = {
                      ...item,
                      disabled: true,
                      date,
                    };
                  }
                  return item;
                } else if (value?.action == "Lost") {
                  return {
                    id: "Lost",
                    action: (row) => handleOpenLostDialog(row),
                    disabled: value?.disabled,
                    date,
                  };
                } else if (value?.action === tableActions.DONE_ELSEWHERE) {
                  return {
                    id: value?.action,
                    action:() => updatePatientActions(
                        value?.action,
                        row?.user_id,
                        row?.id,
                        true,
                        row?.ipr_id
                    ),
                    disabled: value?.disabled,
                    date,
                  };
                } else {
                  let item = {
                    id: value?.action,
                    action: () =>
                      updatePatientActions(
                        value?.action,
                        row?.user_id,
                        row?.id,
                        false,
                        row?.ipr_id
                      ),
                    disabled: value?.disabled,
                    date,
                  };
                  if (value?.action == "Completed") {
                    if (
                      isScanAtHome &&
                      row.scan_at_home_details.payment_status != "Paid"
                    ) {
                      item = {
                        ...item,
                        disabled: true,
                        date,
                      };
                    } else {
                      item = {
                        ...item,
                        disabled: value?.disabled,
                        date,
                      };
                    }
                  }
                  return item;
                }
              });

              return (
                <TableRow className={classes.tableRow} key={index}>
                  {headCells?.map((headCell) => {
                    return (
                      !headCell.hideRow && (
                        <EnhancedTableCell
                          key={`${headCell.id}${index}`}
                          headCell={headCell}
                          row={row}
                          menuItems={menuAction}
                          classes={classes}
                          setOpenNote={setOpenNote}
                          products={products}
                          isScanAtHome={isScanAtHome}
                        />
                      )
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
}

export function TableMenu({ row, menuItems }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openNote, setOpen] = useState(0);
  const { t } = useTranslation();
  const [note, setNote] = useState("");
  const [notificationLoading, setnotificationLoading] = useState(false);

  const handleClose = () => {
    setOpen(0);
  };
  const handleConfirm = async () => {
    if (note) {
      let body = { comment: note };
      try {
        setnotificationLoading(true);
        const res = await axios.post(
          `/admin/v1/users/${openNote}/comments`,
          body
        );
        if (res.data.success) {
        }
      } catch (err) {
        console.log(err);
      } finally {
        setOpen(0);
      }
    }
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleClickAnchor = (event) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <Fragment>
      <Button
        disabled={clinicImpersonated()}
        className={classes.horizontalMenu}
        onClick={handleClickAnchor}
      >
        <MoreHorizIcon />
      </Button>
      <Menu
        key={row.id}
        id={row.id}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        className={classes.menu}
        onClose={() => handleMenuClose()}
      >
        {menuItems &&
          menuItems.map((item) => (
              <MenuItem
                onClick={(e) => {
                  if (!item?.disabled) {
                    e.preventDefault();
                    item.action(row);
                    handleMenuClose();
                  }
                }}
                id={item.id}
                key={item.id}
                disabled={item?.disabled}
                style={{ boxShadow: "inset 0 -1px 0 0 #DFE3E8"}}
              >
                <Box className={classes.menuWrapper}>
                {t(item.id)}
                <br />
                {item?.date ? item.date : ''}
            </Box>
              </MenuItem>
          ))}
        <Button
          disabled={clinicImpersonated()}
          className={classes.addNote}
          onClick={() => {
            setOpen(row.user_id);
          }}
        >
          {t("Add-Note")}
        </Button>
      </Menu>
      <ConfirmationDialog
        open={openNote > 0}
        handleOutsideClick={() => {
          handleClose();
        }}
        handleClose={() => {
          handleClose();
        }}
        handleConfirm={() => {
          handleConfirm();
        }}
        confirmationButtonText={t("Save")}
        cancelButtonText={t("Cancel")}
        dialogTitle={t("Add Note")}
        dialogContentText=""
        loading={notificationLoading}
      >
        <Edit data={note} setData={setNote} />
      </ConfirmationDialog>
    </Fragment>
  );
}
export default function EnhancedTable(props) {
  const {
    rowsData,
    headCells,
    ipr,
    menu,
    // defaultOrder,
    // defaultOrientation,
    perPage,
    setPerPage,
    total,
    handleOpenDialog,
    handleOpenScheduleDialog,
    handleOpenLostDialog,
    setField,
    setSort,
    sort,
    page,
    patient,
    setPage,
    setOpenNote,
    products = [],
    isScanAtHome = false,
    handleTableLoading,
    loading,
    updatePatientActions,
  } = props;
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [rows, setRows] = React.useState(rowsData);
  React.useEffect(() => {
    setRows(rowsData);
  }, [rowsData]);

  const handlePrev = () => {
    setPage((prev) => prev - 1);
  };
  const handleNext = () => {
    setPage((prev) => prev + 1);
  };
  const handleFirstPage = () => {
    setPage(1);
  };
  const handleLastPage = () => {
    setPage(Math.ceil(total / perPage));
  };
  return (
    <div className={classes.root}>
      <Paper>
        {rows.length === 0 ? (
          <div className={classes.noAppointmentWrapper}>
            {patient ? (
              <PersonIcon className={classes.noAppointment} />
            ) : (
              <CalendarTodayIcon className={classes.noAppointment} />
            )}
            <Typography className={classes.noAppointmentsTypo}>
              No {patient ? "patients" : "appointments"} to show
            </Typography>
          </div>
        ) : (
          <Fragment>
            <TableSection
              updatePatientActions={updatePatientActions}
              headCells={headCells}
              handleTableLoading={handleTableLoading}
              loading={loading}
              rows={rows}
              // defaultOrientation={defaultOrientation}
              page={page}
              setField={setField}
              setSort={setSort}
              sort={sort}
              handleOpenDialog={handleOpenDialog}
              handleOpenScheduleDialog={handleOpenScheduleDialog}
              handleOpenLostDialog={handleOpenLostDialog}
              rowsPerPage={perPage}
              menu={menu}
              ipr={ipr}
              classes={classes}
              // defaultOrder={defaultOrder}
              setOpenNote={setOpenNote}
              products={products}
              isScanAtHome={isScanAtHome}
            />
          </Fragment>
        )}
        <div className={classes.paginateWrapper}>
          <div className={classes.selectWrapper}>
            <FormControl>
              <Select
                className={classes.select}
                value={perPage}
                onChange={(e) => {
                  setPerPage(e.target.value);
                  setPage(1);
                }}
              >
                <MenuItem value={10}>{t("1") + t("0")}</MenuItem>
                <MenuItem value={15}>{t("1") + t("5")}</MenuItem>
                <MenuItem value={20}>{t("2") + t("0")}</MenuItem>
                <MenuItem value={50}>{t("5") + t("0")}</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className={classes.buttonWrapper}>
            <button
              disabled={page === 1}
              className={classes.arrowButton}
              onClick={handleFirstPage}
            >
              {i18n.language == "ar" ? (
                <KeyboardDoubleArrowRightIcon />
              ) : (
                <KeyboardDoubleArrowLeftIcon />
              )}
            </button>
            <button
              disabled={page === 1}
              className={classes.arrowButton}
              onClick={handlePrev}
            >
              {i18n.language == "ar" ? (
                <ArrowForwardIosIcon />
              ) : (
                <ArrowBackIos />
              )}
            </button>
            <Box>
              {convertNumberToArabic(t, page)} /{" "}
              {total
                ? convertNumberToArabic(t, Math.ceil(total / perPage))
                : t("1")}
            </Box>
            <button
              disabled={page >= Math.ceil(total / perPage)}
              className={classes.arrowButton}
              onClick={handleNext}
            >
              {i18n.language == "ar" ? (
                <ArrowBackIos />
              ) : (
                <ArrowForwardIosIcon />
              )}
            </button>
            <button
              disabled={page >= Math.ceil(total / perPage)}
              className={classes.arrowButton}
              onClick={handleLastPage}
            >
              {i18n.language == "ar" ? (
                <KeyboardDoubleArrowLeftIcon />
              ) : (
                <KeyboardDoubleArrowRightIcon />
              )}
            </button>
          </div>
        </div>
      </Paper>
    </div>
  );
}
