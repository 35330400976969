import { Box, Button, makeStyles, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import axios from '../../util/axios'
import { clinicImpersonated, FILE, imageScansValue, imageValue } from '../../util/functions';
import XRayFileList from './XRayFileList';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

const useStyles = makeStyles(()=>{return {
  fontFamilyAlmarai:{
    fontFamily:'Almarai-semi !important'
  },
  fontFamilyEina: {
    fontFamily: "Eina-semi !important",
  },
  wrapper:{
    flex:1,
    backgroundColor: '#fff',
    borderRadius: '3px',
    padding: '1rem',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    ['@media only screen and (max-width: 1170px) '] : {
      width: '100%',
      marginLeft: '0'
    },
  },
  imageStyle:{
    borderRadius: '2.8px',
    backgroundColor: '#DFE3E8',
    margin : '1rem 0'
  },
  color:{
    color: '#637381',
  },
  typoWrite:{
    fontSize: '18px !important',
    letterSpacing: 0,
    lineHeight: '32px',
    marginBottom:'0.8rem'
  },
  midTitle:{
    fontSize: '14px !important',
    letterSpacing: 0,
    lineHeight: '20px',
    // marginBottom:'1rem'
  },
  bold:{
    fontWeight:'bold',
  },
  semiBold:{
    fontWeight:'600',
  },
  thin:{
    fontWeight:'400',
  },
  imgBox:{
    display:'flex',
    flexWrap:'wrap',
  },
  btn:{
    padding:0,
    fontFamily: 'Helvetica',
    fontSize: '11px !important',
    letterSpacing: 0,
    lineHeight: '13px',
    textAlign: 'right',
    color: '#007ACE',
    '&:hover':{
      backgroundColor: '#FFF'
    }
  },
  flexBox:{
    display: 'flex',
    justifyContent:'space-between'
  },
  widthSM:{
    width:'10rem'
  },
  marginLeft:{
    marginLeft:'1rem'
  },
  marginTop:{
    marginTop:'1rem'
  },
  remainingImages:{
    display:'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding:'1rem',
    fontWeight:'600'
  },
  playIcon:{
    position: 'absolute',
    opacity: 0.5,
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)'
  }
}})
export default function UploadedFiles() {
  const classes = useStyles();
  const { id } = useParams();
  let perPage = 5;
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [hasUploadedFiles, setHasUploadedFiles] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isListEmpty, setIsListEmpty] = useState(true);
  const [items, setItems] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentImage, setCurrentImage] = useState("");
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const [imagePreviewImage1, setImagePreviewImage1] = useState('');
  const [imagePreviewImage2, setImagePreviewImage2] = useState('');
  const [imagePreviewImage3, setImagePreviewImage3] = useState('');
  const [imagePreviewImage4, setImagePreviewImage4] = useState('');
  const [imagePreviewXRay, setImagePreviewXRay] = useState("");
  const [imagePreviewGumScore, setImagePreviewGumScore] = useState("");
  const [redoScans, setRedoScans] = useState(null);
  const [memberShip, setMemberShip] = useState([])



  const handleOnClickImage = (e) => {
    setCurrentImage(e.currentTarget.id);
    setIsOpen(true);
  };

  const fetchUploadedFiles = async () =>{
    try{
      const result = await Promise.all([
        axios
          .get(
            `admin/v1/users/${id}/storage?per_page=${perPage}&page=${currentPage}`),
        axios.get(`/admin/v1/user/${id}/photoId/`),
        axios
          .get("admin/v1/users/" + id + "/impressions&photos"),
        axios.get(`admin/v1/users/${id}/documents?redo=true&type=BAS`),
        axios.get(`/admin/v1/user/${id}/membershipId/`),
      ]);
      !result[0].data.data.data.length
        ? setIsListEmpty(true)
        : setIsListEmpty(false);
      setTotalPages(Math.ceil(result[0]?.data?.data?.total / perPage));
      if (result[0]?.data?.data && result[0]?.data?.data?.data) {
        const itemData = result[0]?.data?.data?.data;
        setItems(itemData);
        if (itemData && itemData.length > 0) {
          setHasUploadedFiles(true);
        }
      }
      if( result[1]?.data?.success){
        if(result[1]?.data?.user_photo_id?.photo_front_id_url){
          const previewImage1Data = result[1].data?.user_photo_id?.photo_front_id_url
          setImagePreviewImage1(previewImage1Data);
          if (previewImage1Data) {
            setHasUploadedFiles(true);
          }
        }
        if(result[1]?.data?.user_photo_id?.photo_back_id_url){
          const previewImage2Data = result[1].data?.user_photo_id?.photo_back_id_url;
          setImagePreviewImage2(previewImage2Data);
          if (previewImage2Data) {
            setHasUploadedFiles(true);
          }
        }
      }
      if (
        result[2]?.data?.data?.step4 &&
        result[2]?.data?.data?.step4?.x_ray_url
      ) {
        const imagePreviewXrayData = result[2]?.data?.data?.step4?.x_ray_url;
        setImagePreviewXRay(imagePreviewXrayData);
        if (imagePreviewXrayData) {
          setHasUploadedFiles(true);
        }
      }
      if (result[2]?.data?.data?.step4 && result[2]?.data?.data?.step4?.gum_score_url) {
        const imagePreviewGumScoreData = result[2]?.data?.data?.step4?.gum_score_url;
        setImagePreviewGumScore(imagePreviewGumScoreData);
        if (imagePreviewGumScoreData) {
          setHasUploadedFiles(true);
        }
      }
      if (result[3]?.data?.success) {
        const redoScanData = result[3]?.data?.data?.data;
        setRedoScans(redoScanData);
        if (redoScanData && redoScanData.length > 0) {
          setHasUploadedFiles(true);
        }
      }
      if(result[4]?.data.success){
        if(result[4]?.data?.member_ship_id?.membership_image_one_url){
          const imagePreviewImage3Data = result[4].data?.member_ship_id?.membership_image_one_url;
          setImagePreviewImage3(imagePreviewImage3Data);
          if (imagePreviewImage3Data) {
            setHasUploadedFiles(true);
          }
        }
        if(result[4]?.data?.member_ship_id?.membership_image_two_url){
          const imagePreviewImage4Data = result[4].data?.member_ship_id?.membership_image_two_url;
          setImagePreviewImage4(imagePreviewImage4Data);
          if (imagePreviewImage4Data) {
            setHasUploadedFiles(true);
          }
        }
      }
      setLoading(false);
    }catch(err){
      setLoading(false);
      console.log(err);
    }
  }

  useEffect(()=>{
    const ac = new AbortController()
    fetchUploadedFiles();
    return () => ac.abort();

  },[])
  const requiredArray = ['note','updated_at','created_at','id','type','user_id','content'];
  const requiredRedoScanArray = ['document','bite_scan_1', 'bite_scan_2', 'lower_scan' ];
  if (!hasUploadedFiles) {
    return <></>
  }
  return (
    <Box className={`${classes.wrapper}`}>
      <Typography className={`${classes.typoWrite} ${classes.color} ${classes.bold} ${i18n.language == 'ar' ? classes.fontFamilyAlmarai : classes.fontFamilyEina}`}>{t('Uploaded-Files')}</Typography>

      {items?.length > 0 && items?.sort(function(a, b){return b.type - a.type}).map((res)=>{
        let type = '';
        if(res.type == 'Post Redo'){
          type = 'post-redo';
        }else if(res.type == 'Post IPR'){
          type = 'post-ipr';
        }else{
          type = res.type;
        }

        return (
          <Box key={res.id}>
            <Box className={classes.flexBox}>
              <Typography className={`${classes.color} ${classes.midTitle} ${classes.semiBold} ${i18n.language == 'ar' ? classes.fontFamilyAlmarai : classes.fontFamilyEina}`}>{res.type}</Typography>
              <Button onClick={()=> navigate(`/dashboard/users/${id}/files/${res.id}/${type}`, { state: { dataType: res.type }})} className={classes.btn}>{clinicImpersonated() ? t('View') : t('Edit')}</Button>
            </Box>
            <Box className={`${classes.imgBox}`}>
              {res?.user_storage_files?.map((key, index) => {
                const isvideo = key?.file_type?.includes('video');
                const isImage = key?.file_type?.includes('image');
                if(index <  7){
                  return(
                    <Box className={index == 0 ? `${classes.marginTop}` : `${classes.marginTop} ${classes.marginLeft}`} style={{position: 'relative'}}>
                      {isvideo &&
                        <PlayCircleOutlineIcon className={classes.playIcon}/> }
                      <Box
                        key={index}
                        component={ isvideo ? "video" : "img"}
                        sx={{ height: '69.87px', width:'70.31px'}}
                        src={isvideo || isImage ? key.file_url : "/assets/images/Fileicon.svg"}
                      />
                    </Box>
                  );
                }else if(index == 7){
                  return <Box className={`${classes.remainingImages} ${classes.color}`}>+{ res?.user_storage_files.length - 7}</Box>
                }
              })}
            </Box>

          </Box>
        )
      })}
      {redoScans?.map((value, index) => {
        return (
          <Box style={{margin:'1rem 0'}} key={value.id}>
            <Box className={classes.flexBox}>
              <Typography className={`${classes.color} ${classes.midTitle} ${classes.semiBold}`}>{t('Redo-Scans')}</Typography>
              <Button onClick={()=> navigate(`/dashboard/users/${id}/redo-scan/${value.id}`)} className={classes.btn}>{clinicImpersonated() ? t('View') : t('Edit')}</Button>
            </Box>
            <Box className={`${classes.imgBox}`}>
              {Object.keys(value).map((key, index) => {
                if(value[key] && requiredRedoScanArray.indexOf(key) != -1){
                  return <Box
                    key={index}
                    component={"img"}
                    className={index == 0 ? `${classes.marginTop}` : `${classes.marginTop} ${classes.marginLeft}`}
                    sx={{ height: '69.87px', width:'70.31px'}}
                    src={imageValue(value[key])}
                  />
                }
              })}
            </Box>

          </Box>
        )
      })}
      <XRayFileList userId={id} imagePreviewXRay={imagePreviewXRay} imagePreviewGumScore={imagePreviewGumScore}/>

      {(imagePreviewImage1 || imagePreviewImage2) &&
        <>
          <Box className={classes.flexBox} style={{margin:'1rem 0'}}>
            <Typography className={`${classes.color} ${classes.midTitle} ${classes.semiBold} ${i18n.language == 'ar' ? classes.fontFamilyAlmarai : classes.fontFamilyEina}`}>{t('Photo ID')}</Typography>
            <Button onClick={()=> navigate(`/dashboard/users/${id}/addPhotoId`)} className={classes.btn}>{clinicImpersonated() ? t('View') : t('Edit')}</Button>
          </Box>
          <Box  className={`${classes.flexBox} ${classes.widthSM}`}>
            {imagePreviewImage1 && <Box component="img" style={{marginTop: '1rem'}} sx={{ height: '69.87px', width:'70.31px'}} src={imagePreviewImage1} />}
            {imagePreviewImage2 && <Box component="img" style={{marginTop: '1rem'}} sx={{ height: '69.87px', width:'70.31px'}} src={imagePreviewImage2} />}
          </Box>
        </>}
      {(imagePreviewImage3 || imagePreviewImage4) &&
        <>
          <Box className={classes.flexBox} style={{margin:'1rem 0'}}>
            <Typography className={`${classes.color} ${classes.midTitle} ${classes.semiBold} ${i18n.language == 'ar' ? classes.fontFamilyAlmarai : classes.fontFamilyEina}`}>{t('membership-id')}</Typography>
            <Button onClick={()=> navigate(`/dashboard/users/${id}/memberShipId`)} className={classes.btn}>{clinicImpersonated() ? t('View') : t('Edit')}</Button>
          </Box>
          <Box  className={`${classes.flexBox} ${classes.widthSM}`}>
            {imagePreviewImage3 && <Box component="img" style={{marginTop: '1rem'}} sx={{ height: '69.87px', width:'70.31px'}} src={imagePreviewImage3} />}
            {imagePreviewImage4 && <Box component="img" style={{marginTop: '1rem'}} sx={{ height: '69.87px', width:'70.31px'}} src={imagePreviewImage4} />}
          </Box>
        </>}
    </Box>
  )
}
