import { Box, Button, CircularProgress, makeStyles, TextareaAutosize, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react';
import axios from "../../util/axios";
import { useTranslation } from 'react-i18next';
import { clinicImpersonated } from '../../util/functions';
import parse from "react-html-parser";

const useStyles = makeStyles(() => {
  return {
    photoWrapper: {
      padding: '2rem',
      boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)'
    },
    saveBtn: {
      display: 'block',
      width: '65px',
      marginTop: '0.5rem',
      marginLeft: 'auto',
      borderRadius: '4.7px',
      color: '#fff',
      backgroundColor: '#FF2C46',
      '&:hover': {
        backgroundColor: '#FF2C46',
      }
    },
    errorMsg: {
      margin: '1rem 0',
      color: 'red'
    },
    loadingBox: {
      margin: "0 auto",
      textAlign: "center",
      padding: 30,
      color: '#FF2C46'
    },
    loading: {
      textAlign: 'center',
      margin: "0 auto",
      color: '#ff2c46'
    },
    textarea: {
      width: '100%',
      border: 'none',
      resize: 'none',
      backgroundColor: 'rgba(211,215,219,0.25)',
      color: '#939393',
      padding: '0.8rem',
      '&:focus': {
        outline: 'none'
      }
    },
    flexBox: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '0.5rem'
    },
    fullWidth: {
      width: '100%'
    },
  }
});

export default function InformationMallCollab({ isMandatory, userId, handleIconInformationView, information, handleInformationValue }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const classes = useStyles();
  const [data, setData] = useState(null);
  const [errorMsg, setErrorMsg] = useState({
    problem_question: '',
  });

  const handleChange = (value, type) => {
    if (!clinicImpersonated()) {
      if (type == 'problem_question') {
        setErrorMsg(prev => ({
          ...prev,
          [type]: '',
        }));
      }
      setSuccess(false);
      setData((prev) => ({
        ...prev,
        [type]: value,
      }))
    }
  }

  const handleSave = () => {
    let errMsg = {
      problem_question: '',
    }
    setErrorMsg(errMsg);
    if (isMandatory && !data?.problem_question) {
      errMsg = {
        ...errMsg,
        problem_question: t('radio-required'),
      }
      setErrorMsg(errMsg);
    } else {
      setLoading(true);
      let body = {
        ...data,
      };
      if (body) {
        axios
          .post(`admin/v1/users/${userId}/your-photos`, body)
          .then((res) => {
            if (res.data.success) {
              handleIconInformationView(res.data.isAllProvided);
              handleDatafetched(res.data.data);
              handleInformationValue(res.data.data);
              setSuccess(true);
            }

          })
          .catch((err) => {
            setSuccess(false);
            console.log(err);
          }).finally(() => {
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    }
  }

  const handleDatafetched = (value) => {
    setData({
      problem_question: value?.problem_question,
      dental_history_na: value?.dental_history_na == 1 ? true : false,
    });
  }

  useEffect(() => {
    handleDatafetched(information);
  }, [])

  return (
    <Box className={classes.photoWrapper}>
      {loading ? <Box className={classes.loadingBox}><CircularProgress className={classes.loading} /></Box> :
        <>
          <Box style={{ marginBottom: '1rem' }}>
            <Typography style={{ color: '#363636' }}>{parse(t('What-problem-are-you-looking-to-get-fixed?'))}</Typography>
            <Box className={`${classes.fullWidth} ${classes.flexBox}`}>
              <TextareaAutosize
                disabled={clinicImpersonated()}
                placeholder={t('Add-comment...')}
                className={classes.textarea}
                style={{ height: '6rem' }}
                value={data?.problem_question}
                onChange={(e) => handleChange(e.target.value, 'problem_question')}
              />
            </Box>
          </Box>
          {errorMsg?.problem_question && <Typography className={classes.errorMsg}>{errorMsg.problem_question}</Typography>}

          <Button disabled={clinicImpersonated()} className={classes.saveBtn} onClick={handleSave}>{success ? t('Saved') : t('Save')}</Button>
        </>
      }
    </Box>
  )
}
