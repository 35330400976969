import {
    Box,
    LinearProgress,
    Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from 'react'
import leftBite from '../../assets/images/fv-info-details/leftbite.svg';
import lowerOcclusal from '../../assets/images/fv-info-details/Bottombite.svg';
import rightBite from '../../assets/images/fv-info-details/rightbite.svg';
import upperOcclusal from '../../assets/images/fv-info-details/Topbite.svg';
import centerBite from '../../assets/images/fv-info-details/centerbite.svg';
import frontSmile from '../../assets/images/fv-info-details/frontbigsmile.svg';
import frontNoSmile from '../../assets/images/fv-info-details/frontnosmile.svg';
import profile from '../../assets/images/fv-info-details/profile.svg';
import SinglePhoto from './SinglePhoto';
import { Checkbox } from "@shopify/polaris";
import axios from "../../util/axios";
import axiosAws from "axios";
import { useParams } from "react-router-dom";

const ScreeningPhotos = ({
    imagePreview,
    appointmentType,
    disable,
    setSuccess,
    appointmentId,
    handleDatafetched,
    classes,
    setErrorMsg,
    t,
}) => {
    const { id } = useParams();
    const [disabled, setDisabled] = useState(false);
    const [uploadProgress, setUploadProgress] = useState({});
    const [photos, setPhotos] = useState({
        front_facing_big_smile: {
            value: null,
            value_na: false,
            title: t('Upload-image'),
        },
        front_facing_no_smile: {
            value: null,
            value_na: false,
            title: t('Upload-image'),
        },
        right_side_profile_no_smile: {
            value: null,
            value_na: false,
            title: t('Upload-image'),
        },
        center_bite: {
            value: null,
            title: t('Upload-image'),
        },
        right_bite: {
            value: null,
            title: t('Upload-image'),
        },
        left_bite: {
            value: null,
            title: t('Upload-image'),
        },
        upper_teeth: {
            value: null,
            title: t('Upload-image'),
        },
        lower_teeth: {
            value: null,
            title: t('Upload-image'),
        },
        isReviewed: false,
    });

    const handleImages = (files, id) => {
        setErrorMsg("");
        setSuccess(false);
        setDisabled(true);
        const imageId = id;
        const file = files[0];
        saveInAws(file, imageId);
    };

    const handleUploadError = (err) => {
        setErrorMsg("Upload failed. Please try again.");
        console.log(err);
    };

    const uploadConfig = (progressEvent, name) => {
        if (progressEvent.loaded === progressEvent.total) {
            delete uploadProgress[name];
            setUploadProgress(uploadProgress);
        } else {
            const array = {
                ...uploadProgress,
                [name]: Math.round((progressEvent.loaded * 100) / progressEvent.total),
            };
            setUploadProgress(array);
        }
    };

    const saveInAws = (value, type) => {
        let imageType = value.type;
        const form_data = new FormData();
        form_data.append("content_type", imageType);
        form_data.append("file_name", value.name);
        axios
            .post("/admin/v1/images/s3", form_data)
            .then((res) => {
                let items = [
                    {
                        key: type,
                        value: res.data.key,
                    },
                ];
                const url2 = res.data.url;
                axiosAws
                    .put(url2, value, {
                        headers: {
                            "x-amz-acl": ["public-read-write"],
                            "Content-Type": imageType,
                        },
                        onUploadProgress: (event) => uploadConfig(event, type),
                    })
                    .then(() => {
                        handleSave(items);
                    })
                    .catch((err) => {
                        setDisabled(false);
                        handleUploadError(type, err);
                    });
            })
            .catch((err) => {
                setDisabled(false);
                handleUploadError(type, err);
            });
    };

    const handleSave = (items) => {
        setErrorMsg("");
        const form_data = new FormData();
        items.forEach((item) => {
            form_data.append(item.key, item.value);
        });
        form_data.append("procedure", appointmentType?.procedure);
        form_data.append("user_scan_info_id", appointmentId);
        form_data.append("id", imagePreview?.id);
        form_data.append("parent_id", imagePreview?.parent_id);
        axios.post(`admin/v2/users/${id}/case-record-screening-photos`, form_data).then((res) => {
            if (res.data.success) {
                handleDatafetched(res.data.data);
                setSuccess(true);
            } else {
                setSuccess(false);
            }
        }).catch((err) => {
            setErrorMsg("Upload failed. Please try again.");
            console.log(err);
        }).finally(() => setDisabled(false));
    };

    const handleChangeNotApplicable = (prop) => {
        let items = [
            {
                key: prop,
                value: !imagePreview?.[prop] ? 1 : 0,
            },
        ];
        handleSave(items);
    };

    useEffect(() => {
        if(imagePreview) {
            let frontFacingBigSmile = imagePreview?.screening_photo_files?.find(img => img.name === 'front_facing_big_smile');
            let frontFacingNoSmile = imagePreview?.screening_photo_files?.find(img => img.name === 'front_facing_no_smile');
            let rightSideProfileNoSmile = imagePreview?.screening_photo_files?.find(img => img.name === 'right_side_profile_no_smile');
            let centerBiteImg = imagePreview?.screening_photo_files?.find(img => img.name === 'center_bite');
            let rightBiteImg = imagePreview?.screening_photo_files?.find(img => img.name === 'right_bite');
            let leftBiteImg = imagePreview?.screening_photo_files?.find(img => img.name === 'left_bite');
            let upperTeeth = imagePreview?.screening_photo_files?.find(img => img.name === 'upper_teeth');
            let lowerTeeth = imagePreview?.screening_photo_files?.find(img => img.name === 'lower_teeth');
            setPhotos({
                front_facing_big_smile: {
                    value: frontFacingBigSmile ? frontFacingBigSmile?.file_url : frontSmile,
                    value_na: imagePreview?.front_facing_big_smile_na ?? false,
                    title: !frontFacingBigSmile ? t('Upload-image') : t('Replace'),
                },
                front_facing_no_smile: {
                    value: frontFacingNoSmile ? frontFacingNoSmile?.file_url : frontNoSmile,
                    value_na: imagePreview?.front_facing_no_smile_na ?? false,
                    title: !frontFacingNoSmile ? t('Upload-image') : t('Replace'),
                },
                right_side_profile_no_smile: {
                    value: rightSideProfileNoSmile ? rightSideProfileNoSmile?.file_url : profile,
                    value_na: imagePreview?.right_side_profile_no_smile_na ?? false,
                    title: !rightSideProfileNoSmile ? t('Upload-image') : t('Replace'),
                },
                center_bite: {
                    value: centerBiteImg ? centerBiteImg?.file_url : centerBite,
                    title: !centerBiteImg ? t('Upload-image') : t('Replace'),
                },
                right_bite: {
                    value: rightBiteImg ? rightBiteImg?.file_url : rightBite,
                    title: !rightBiteImg ? t('Upload-image') : t('Replace'),
                },
                left_bite: {
                    value: leftBiteImg ? leftBiteImg?.file_url : leftBite,
                    title: !leftBiteImg ? t('Upload-image') : t('Replace'),
                },
                upper_teeth: {
                    value: upperTeeth ? upperTeeth?.file_url : upperOcclusal,
                    title: !upperTeeth ? t('Upload-image') : t('Replace'),
                },
                lower_teeth: {
                    value: lowerTeeth ? lowerTeeth?.file_url : lowerOcclusal,
                    title: !lowerTeeth ? t('Upload-image') : t('Replace'),
                },
                isReviewed: imagePreview?.review_status ? true : false,
            });
        }
    }, [imagePreview])

    return (
        <>
            <div>
                <Box className={`${classes.photoBox} ${classes.photoWidthSM}`}>
                    <div className={classes.photoCheckWrapper}>
                        <Box className={classes.singlePhotoBox}>
                            <SinglePhoto
                                disable={photos.isReviewed || disable || disabled}
                                value={photos.front_facing_big_smile.value}
                                handleImages={handleImages}
                                id={"front_facing_big_smile"}
                                title={photos.front_facing_big_smile.title}
                            />
                            <Typography className={classes.imageTitle}>
                                {t("Front-Facing-Big-Smile")}
                            </Typography>
                            {uploadProgress?.front_facing_big_smile && (
                                <Box>
                                    <LinearProgress
                                        variant="determinate"
                                        value={uploadProgress}
                                    />
                                </Box>
                            )}
                        </Box>
                        <div className={classes.checkBoxWrapper}>
                            <Checkbox
                                label={t("not-applicable")}
                                checked={photos.front_facing_big_smile.value_na}
                                disabled={photos.isReviewed || disable || disabled}
                                onChange={() =>
                                    handleChangeNotApplicable("front_facing_big_smile_na")
                                }
                            />
                        </div>
                    </div>

                    <div className={classes.photoCheckWrapper}>
                        <Box className={classes.singlePhotoBox}>
                            <SinglePhoto
                                disable={photos.isReviewed || disable || disabled}
                                value={photos.front_facing_no_smile.value}
                                handleImages={handleImages}
                                id={"front_facing_no_smile"}
                                title={photos.front_facing_no_smile.title}
                            />
                            <Typography className={classes.imageTitle}>
                                {t("Front-Facing-No-Smile")}
                            </Typography>
                            {uploadProgress?.front_facing_no_smile && (
                                <Box>
                                    <LinearProgress
                                        variant="determinate"
                                        value={uploadProgress?.front_facing_no_smile}
                                    />
                                </Box>
                            )}
                        </Box>
                        <div className={classes.checkBoxWrapper}>
                            <Checkbox
                                label={t("not-applicable")}
                                checked={photos.front_facing_no_smile.value_na}
                                disabled={photos.isReviewed || disable || disabled}
                                onChange={() =>
                                    handleChangeNotApplicable("front_facing_no_smile_na")
                                }
                            />
                        </div>
                    </div>

                    <div className={classes.photoCheckWrapper}>
                        <Box className={classes.singlePhotoBox}>
                            <SinglePhoto
                                disable={photos.isReviewed || disable || disabled}
                                value={photos.right_side_profile_no_smile.value}
                                handleImages={handleImages}
                                id={"right_side_profile_no_smile"}
                                title={photos.right_side_profile_no_smile.title}
                            />
                            <Typography className={classes.imageTitle}>
                                {t("Profile-No-Smile")}
                            </Typography>
                            {uploadProgress?.right_side_profile_no_smile && (
                                <Box>
                                    <LinearProgress
                                        variant="determinate"
                                        value={uploadProgress?.right_side_profile_no_smile}
                                    />
                                </Box>
                            )}
                        </Box>
                        <div className={classes.checkBoxWrapper}>
                            <Checkbox
                                label={t("not-applicable")}
                                checked={photos.right_side_profile_no_smile.value_na}
                                disabled={photos.isReviewed || disable || disabled}
                                onChange={() =>
                                    handleChangeNotApplicable("right_side_profile_no_smile_na")
                                }
                            />
                        </div>
                    </div>
                </Box>

                <Box className={`${classes.photoBox} ${classes.photoWidth}`}>
                    <Box className={classes.singlePhotoBox}>
                        <SinglePhoto
                            disable={photos.isReviewed || disable || disabled}
                            value={photos.center_bite.value}
                            handleImages={handleImages}
                            id={"center_bite"}
                            title={photos.center_bite.title}
                        />
                        <Typography className={classes.imageTitle}>
                            {t("Center-Bite")}
                        </Typography>
                        {uploadProgress?.center_bite && (
                            <Box>
                                <LinearProgress
                                    variant="determinate"
                                    value={uploadProgress?.center_bite}
                                />
                            </Box>
                        )}
                    </Box>

                    <Box className={classes.singlePhotoBox}>
                        <SinglePhoto
                            disable={photos.isReviewed || disable || disabled}
                            value={photos.right_bite.value}
                            handleImages={handleImages}
                            id={"right_bite"}
                            title={photos.right_bite.title}
                        />
                        <Typography className={classes.imageTitle}>
                            {t("Right-Bite")}
                        </Typography>
                        {uploadProgress?.right_bite && (
                            <Box>
                                <LinearProgress
                                    variant="determinate"
                                    value={uploadProgress?.right_bite}
                                />
                            </Box>
                        )}
                    </Box>
                    <Box className={classes.singlePhotoBox}>
                        <SinglePhoto
                            disable={photos.isReviewed || disable || disabled}
                            value={photos.left_bite.value}
                            handleImages={handleImages}
                            id={"left_bite"}
                            title={photos.left_bite.title}
                        />
                        <Typography className={classes.imageTitle}>
                            {t("Left-Bite")}
                        </Typography>
                        {uploadProgress?.left_bite && (
                            <Box>
                                <LinearProgress
                                    variant="determinate"
                                    value={uploadProgress?.left_bite}
                                />
                            </Box>
                        )}
                    </Box>

                    <Box className={classes.singlePhotoBox}>
                        <SinglePhoto
                            disable={photos.isReviewed || disable || disabled}
                            value={photos.upper_teeth.value}
                            handleImages={handleImages}
                            id={"upper_teeth"}
                            title={photos.upper_teeth.title}
                        />
                        <Typography className={classes.imageTitle}>
                            {t("Top-Bite")}
                        </Typography>
                        {uploadProgress?.upper_teeth && (
                            <Box>
                                <LinearProgress
                                    variant="determinate"
                                    value={uploadProgress?.upper_teeth}
                                />
                            </Box>
                        )}
                    </Box>

                    <Box className={classes.singlePhotoBox}>
                        <SinglePhoto
                            disable={photos.isReviewed || disable || disabled}
                            value={photos.lower_teeth.value}
                            handleImages={handleImages}
                            id={"lower_teeth"}
                            title={photos.lower_teeth.title}
                        />
                        <Typography className={classes.imageTitle}>
                            {t("Bottom-Bite")}
                        </Typography>
                        {uploadProgress?.lower_teeth && (
                            <Box>
                                <LinearProgress
                                    variant="determinate"
                                    value={uploadProgress?.lower_teeth}
                                />
                            </Box>
                        )}
                    </Box>
                </Box>
            </div>
            {
                imagePreview?.replaced_file &&
                <>
                    <Box className={classes.photoTitleWrapper}>
                        <Typography>{t("replaced-screening-photos")}</Typography>
                    </Box>
                    <ScreeningPhotos
                        imagePreview={imagePreview.replaced_file}
                        appointmentType={appointmentType}
                        disable={disable}
                        setSuccess={setSuccess}
                        appointmentId={appointmentId}
                        handleDatafetched={handleDatafetched}
                        classes={classes}
                        setErrorMsg={setErrorMsg}
                        t={t}
                    />
                </>
            }
        </>
    )
}

export default ScreeningPhotos