import { Box, IconButton, makeStyles, Typography,Checkbox ,FormControlLabel, Button, Menu, Grid, MenuItem, Radio, RadioGroup, TextField, Snackbar, InputLabel  } from '@material-ui/core'
import React, { useEffect, useState, useContext } from 'react'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import EnhancedTable from '../DataTable/EnhancedTable';
import axios from '../../util/axios'
import CircularProgress from "@material-ui/core/CircularProgress";
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';
import Note from '../Note/Note';
import Edit  from '../Note/EditNote' 
import { useNavigate } from 'react-router-dom';
import instance from '../../util/axios';
import { Alert } from '@mui/material';
import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { TabsControllerContext } from '../../Context/Tabs/TabsProvider';
import { useTranslation } from 'react-i18next';
import { clinicImpersonated, convertNumberToArabic, handleReadNotes } from '../../util/functions';
import Cookies from 'js-cookie';
import ActiveContext from '../../Context/Context';
const useStyles=makeStyles((theme)=>{ return {
    fontFamilyAlmarai:{
        fontFamily:'Almarai-semi !important'
    },
    fontFamilyEina: {
        fontFamily: "Eina-semi !important",
    },
    today:{
        flex:"1 1 auto",
        display:"inline-block",
        cursor:"pointer",
    },
    boxWrapper: {
    },
    displayedDate: {
        fontSize: '27px!important',
        fontFamily: 'Eina-semi!important',
        fontWeight: 500,
        width: '8em'
    },
    displayedDateComplex: {
        fontSize: '27px!important',
        fontFamily: 'Eina-semi!important',
        fontWeight: 500,
    },
    box:{
        display:'flex',
        flexWrap: 'wrap',
        alignItems:"center",
        justifyContent:"space-around",
        ['@media only screen and (max-width: 700px) '] : {
            justifyContent:"unset",
            gap: '5%',
            rowGap: "10px !important",
           },
    },
    alertBox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    alert: {
        alignItems: 'center',
    },
    calendarLayout: {
        backgroundColor:"#ff2c46",
        padding:15,
        color:'white',
        fontSize:20,
        display: 'flex',
        alignItems: 'center',
        gap: 5,
    },
    checkContainer:{
     flex:"1 1 auto",
    margin:0,
    minWidth:'fit-content'
    },
    appointment:{
        flex:"1 1 auto",
        textAlign: "center",
        display:"inline-block",
        minWidth:'fit-content',
        color: '#9B9BA1',
        fontWeight: "Eina-semi!important",
        fontSize: '14px!important'
    },
    appointmentHide:{
        color: '#212B36',
        fontWeight:'400',
        fontSize: '12px!important'
    },
    datePicker:{
        flex:"1 1 auto",
        //remove to keep same font
        "& input": {
            fontSize: "2em",
            fontFamily:"Eina-semi!important",
            [theme.breakpoints.between('sm','md')]: {
            fontSize:'1.4em',
         },
         [theme.breakpoints.down('sm')]: {
            fontSize:'1em',
         },
          },
        maxWidth:'20%',
        ["@media only screen and (max-width: 800px) "]: {
            maxWidth: "unset",
            minWidth: "fit-content",
        },
    },
    loadingBox:{
        margin: "0 auto",
        textAlign: "center",
        padding : 30
    },
    loading: {
        textAlign: 'center',
        margin: "0 auto",
        color: '#ff2c46'
    },
    icon:{
         ['@media only screen and (max-width: 800px) '] : {
           padding:0
          },
    },
    calendarButton: {
        borderRadius:50,
        width:'22px',
        height:44
    },
    iconBox:{
        flex:"1 1 auto",
       minWidth:'fit-content'
   },
   alertMargin:{
    marginBottom:"1.3rem"
   },
   menuBox: {
    display: "flex"
    },
    filterButtonWithArrow: {
        border: '1px lightgray solid',
        borderRadius: 0,
        // borderRight: 'none'
    },
    RadioGroup:{
        "& li":{
            paddingLeft:0
        }
    },
    filterWrapper: {
        flex:"1 1 auto",
        justifyContent:'center',
        display: "flex",
        padding: '20px 0',
        margin: '0.5rem 0'
    },
    searchBar: {
        "&:focus":{
            outlineColor: "#ff2c46",
            outlineStyle: "solid",
        },
        '&:focus::WebkitSearchCancelButton':{
            color:"red",
            backgroundColor:"black"
        },
        '&:focus::-ms-clear':{
            color:"red",
            backgroundColor:"black"
        },
        // end
        // width: '60%',
        zIndex:10,
        minWidth: '250px',
        width: '50%',
        paddingLeft: 10,
        height:41,
        border: '1px lightgray solid',
        '&::placeholder': {
            fontWeight: 400,
          },
          "& label.Mui-focused": {
            color: "#ff2c46"
          },
    },
    blackColor: {
        color : '#000'
    },
   addNote: {
    width:"25%",
    textAlign: 'center',
    marginTop: 10,
    backgroundColor:"#FF2C46",
    fontWeight:"700",
    float:"right",
    boxSizing:" border-box",
    border: "1px solid #C4CDD5",
    borderRadius: "3px",
    boxShadow:" 0 1px 0 0 rgba(22,29,37,0.05)",

 } }});

export default function CancelledBooking(){
    const { t, i18n } = useTranslation()
    const moment = extendMoment(originalMoment);
    const navigate = useNavigate();
    const [data, setData] = useState([])
    // const[checkHide,setCheckHide]=useState(false)
    const [page, setPage] = useState(1);
    const [error, setError] = useState(false);
    const [perPage, setPerPage] = useState(10);
    const [field, setField] = useState('created_at');
    const [sort, setSort] = useState('desc')
    const [total, setTotal] = useState(0)
    const [loading, setLoading] = useState(true)
    const [notificationLoading, setnotificationLoading] = useState(true)
    const [tableLoading, setTableLoading] = useState(true)
    // const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorElFilter, setAnchorElFilter] = React.useState(null);
    // const openCalendar = Boolean(anchorEl);
    const open = Boolean(anchorElFilter);

    // const handleClick = (event) => {
    //     setAnchorEl(event.currentTarget);
    //   };
    const handleClickFilter = (event) => {
        setAnchorElFilter(event.currentTarget);
    };
    // const handleCloseCalendar = () => {
    // setAnchorEl(null);
    // };
    const handleTableLoading = (value) => {
        setTableLoading(value);
    }
    //notes
    const[openEditNote,setOpenEditNote]=useState(0)
    const[openAddNote,setOpenAddNote]=useState(0)
    const[notes,setNotes]=useState([])
    const[editNote,setEditNote]=useState({})
    const[newNote,setNewNote]=useState("")
    // end of notes
    const [message, setMessage] = useState('');
    const [singleRow, setSingleRow] = useState({})
    const [openAlert, setOpenAlert] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [openScheduleDialog, setOpenScheduleDialog] = useState(false);
    const classes = useStyles();

    const {
      searchCancelled,
      state,
      refreshComment,
      openNote,
      cancelledFilter,
      actions: {
        setSearch,
        setRefreshComment,
        setCalendarfilter,
        setAppointmentfilter,
        setCancelledFilter,
        setPatientfilter,
        setOpenNote,
        handleCancelledSearch,
        setSearchPatients,
        handleKeyPressCancelled,
        setPendingBooking,
      },
    } = useContext(TabsControllerContext);
    const {
        filesImage,setFilesImage,imagesKey,setImageKey,images,setImages
      } = useContext(ActiveContext);

    //Notes
    useEffect(async()=>{
        if(!openNote){
            setNotes([])
            setOpenEditNote(0)
            setEditNote({})
            return;//not chosen a note
        }
        if(!openEditNote){
            //not chosen a comment to edit but chosen a user to view the note
            try{
            setnotificationLoading(true)
            const res= await axios.get(`/admin/v1/users/${openNote}/comments`)
            if(res.data.success){
                setNotes(res.data.data.data)
                const newData = handleReadNotes(data, openNote, 'unread_comments', false)
                setData(newData)
            }
            }catch(err){
                console.log(err)
            }finally{
            setnotificationLoading(false)
            }
            
        }
        else{
            //chosen a comment to edit display it
            try{
                setnotificationLoading(true)
                const res= await axios.get(`/admin/v1/users/${openNote}/comments/${openEditNote}`)
                if(res.data.success){
                    setEditNote(res.data.data.comment)
                }
                }catch(err){
                    console.log(err)
                }finally{
                setnotificationLoading(false)
                }
        }
    

    },[openNote,openEditNote,openAddNote])
    const handleCloseFilter = () => {
        setAnchorElFilter(null);
    };
    const handleClose=()=>{
        if(openEditNote){
            setnotificationLoading(true)
            setOpenEditNote(0)
            setEditNote({})
        }
        else{
            setNotes([])
            setNewNote("")
            setOpenAddNote(0)
        }
        setFilesImage([])
        setImageKey([])
        setImages([])
    }
    const handleConfirm=async()=>{
        if(openEditNote){
            //update before closing edit modal
            let body={comment:editNote,images:imagesKey}
            try{
                setnotificationLoading(true)
                const res= await axios.post(`/admin/v1/users/${openNote}/comments/${openEditNote}`,body)
                if(res.data.success){
                console.log('updated')
                }
                }catch(err){
                    console.log(err)
                }finally{
                setnotificationLoading(false)
                }
            setnotificationLoading(true)
            setOpenEditNote(0)
            setEditNote({})
        }
        else{
            if(newNote){
                let body={comment:newNote,images:imagesKey}
                try{
                    setnotificationLoading(true)
                    const res= await axios.post(`/admin/v1/users/${openNote}/comments`,body)
                    if(res.data.success){
                    console.log('added')
                    }
                    }catch(err){
                        console.log(err)
                    }finally{
                    setOpenAddNote(0)
                    setNewNote("")
                    }
                }
        }
        setFilesImage([])
        setImageKey([])
        setImages([])
    }
    const handleOutsideClick=()=>{
        setOpenNote(0)
        setOpenAddNote(0)
        setOpenEditNote(0)
        setNewNote("")
        setEditNote({})
        setFilesImage([])
        setImageKey([])
        setImages([])
    }

    //End of notes
    useEffect(()=>{
        setSearch("");
        setSearchPatients("");
        if(!state.value){
            return 
        }
        setTableLoading(true)
        axios.get(`/admin/v1/clinic/cancelled-appointments?clinic_id=${Cookies.get('clinic_id')}&search=${searchCancelled}&page=${page}&type=${sort}&field=${field}&per_page=${perPage}`)
        .then((response) => {
            const dat = response.data.data
            setData(dat)
            setTotal(response.data.total)
        }).catch((err)=> {
            console.log(err)
        }).finally(() => {
            setLoading(false);
            setTableLoading(false)
        })
    },[page, perPage, field, sort, searchCancelled])

    const headCells = [
        {
            id: 'first_name',
            label: t('Patient-name'),
            type: 'appointment_name',
        },
        {
            id: 'created_at',
            label: t('Submitted-Date'),
            color:"#a5a5a5",
        },
        {
            id: 'phone_number',
            label: t('Phone-Number'),
            type:"phone",
            hideSort: true,
        },
        {
            id: 'booking_time',
            label: t('Time'),
            type: 'time',
        },
        {
            id: 'booking_date',
            label: t('Booking-Date'),
            type: 'time',
            preferredTime : 'preferred_time',
            preferredDate : 'preferred_date',
        },
        {
            id: 'user_sub_status',
            label: t('Status'),
            hideSort: true,
        },
        {
            id: 'user_type',
            label: t('Type'),
            type: 'type',
            hideSort: true,
        },
        {
            id: 'user_action',
            label: t('Action'),
            type: 'menu',
            hideSort: true,
        },
        {
            id: 'unread_comments',
            label: '',
            hideRow: true
        },
    ]

    const handleCloseDialog = () => {
        setOpenDialog(false);
    }
    const handleOpenDialog = (row) => {
        setOpenDialog(true);
        setSingleRow(row)
    }

    const refreshPage = () => {
        window.location.reload();
    }

    const handleConfirmReschedule = async(row) => {
        setLoading(true);
        const name = row.first_name.split(' ');
        const lastName = name.pop();
        const firstName = name.shift();
        const email = row.user_email;
        const phone = row.phone_number;
        let sentId = row.id ?? '';
        let isAppointment = true;
        let scn = row.scn ?? '';
        const clinicId = Cookies.get('clinic_id');
        const query = `id=${sentId}&isAppointment=${isAppointment}&scn=${scn}&clinicId=${clinicId}&isReschedule=true`;
        const response = await axios.get(`admin/v1/clinic/${clinicId}/reschedule/booking/${row.user_id}?${query}`)
        setLoading(false);
        if (response?.data?.error) {
            setOpenAlert(true);
            setError(true);
            setMessage(t('This-update-is-not-eligible'));
        } else if(response.data.eligible === true) {
            const user_type = row?.user_type ?? null;
            const pendingBookingId = response?.data?.pendingBookingId;
            setPendingBooking({ id: pendingBookingId, isReschedule: true });
            const query = `id=${row.user_id}&first_name=${firstName}&last_name=${lastName}&email=${email}&phone=${phone}${row.id ? '&usiId=' + row.id : ''}`;
            let navigateToUrl = `/dashboard/booking?${query}`;
            if (user_type) {
                if (user_type.includes("First Visit") || user_type.includes("Walk-in")) {
                    navigateToUrl += '&bas_schedule=true';
                } else {
                    navigateToUrl += '&proc_schedule=true';
                }
            }
            navigate(navigateToUrl);
        } else {
            setOpenAlert(true)
            setMessage(t('This-user-is-not-eligible-to-book-a-scan'));
        }
        setOpenDialog(false);
    }

    const handleConfirmSchedule = async(row) => {
        setLoading(true);
        const name = row.first_name ? row.first_name.split(" ") : [];
        let firstName = '';
        let lastName = '';
        if(name.length > 1){
            firstName = name[0];
            lastName = name[1];
        }
        const email = row.user_email;
        const phone = row.user_phone;
        let sentId = row.id ?? '';
        let isAppointment = true;
        let scn = row.scn ?? '';
        const clinicId = Cookies.get('clinic_id');
        const query = `id=${sentId}&isAppointment=${isAppointment}&scn=${scn}&clinicId=${clinicId}&isReschedule=false`;
        const response = await axios.get(`admin/v1/clinic/${clinicId}/reschedule/booking/${row.user_id}?${query}`)
        setLoading(false);
        if (response?.data?.error) {
            setOpenAlert(true);
            setError(true);
            setMessage(t('This-update-is-not-eligible'));
        } else if(response.data.eligible === true) {
            const user_type = row?.user_type ?? null;
            const pendingBookingId = response?.data?.pendingBookingId;
            setPendingBooking({ id: pendingBookingId, isReschedule: false });
            const query = `id=${row.user_id}&first_name=${firstName}&last_name=${lastName}&email=${email}&phone=${phone}${row.id ? '&usiId=' + row.id : ''}`;
            let navigateToUrl = `/dashboard/booking?${query}`;
            if (user_type) {
                if (user_type.includes("First Visit") || user_type.includes("Walk-in")) {
                    navigateToUrl += '&bas_schedule=true';
                } else {
                    navigateToUrl += '&proc_schedule=true';
                }
            }
            navigate(navigateToUrl);
        } else {
            setOpenAlert(true)
            setMessage(t('This-user-is-not-eligible-to-book-a-scan'))
        }
        setOpenScheduleDialog(false);
    }

    const handleCloseScheduleDialog = () => {
        setOpenScheduleDialog(false);
    }

    const handleOpenScheduleDialog = (row) => {
        setOpenScheduleDialog(true);
        setSingleRow(row)
    }

    const menu = [
        { id: 'Reschedule', action: () => console.log('delete') },
    ]


    const handleCloseAlert = () => {
        setOpenAlert(false);
        setMessage('')
    }

    if(openAlert){
        window.scroll({
            top:0,
            behavior:"smooth"
        })
    }
    const handleRadioButtonClick = (e)=> { 
        if(e.target.tagName=="INPUT"){
            if(e.target.value==""){
                setCalendarfilter("");
                setPatientfilter("");
                setAppointmentfilter("");
                setCancelledFilter("");
            }
            setAnchorElFilter(null);
        }
    }
    return (
        (loading  )? <Box className={classes.loadingBox}><CircularProgress className={classes.loading}/></Box> :
        <Box className={classes.boxWrapper}>
            {openAlert && (
                <Alert
                    open={openAlert}
                    className={classes.alert}
                    severity="error"
                    onClose={handleCloseAlert}
                >
                    <Box className={classes.alertBox}>
                        {message}
                        {error && (
                            <Button variant="outlined" onClick={refreshPage} color="inherit" size="small">
                                {t('refresh')}
                            </Button>
                        )}
                    </Box>
                </Alert>
            )}
             <Box className={classes.box}>
            {/* search field */}
            <Grid className={classes.filterWrapper} item lg={12} xs={12}>
                <input 
                    type='search'
                    id="searchstyle"
                    className={classes.searchBar}
                    placeholder={t('Search')}
                    defaultValue={searchCancelled}
                    onKeyPress={(e) => handleKeyPressCancelled(e)}
                    onChange={(e) => handleCancelledSearch(e)}
                />
            </Grid>
            {/* search field */}

            <Typography variant='body1' className={classes.appointment} >
            {convertNumberToArabic( t, total)} {t('Appointment')}{total === 0 || total > 1 ? i18n.language == 'ar' ? '' : 's' : ''}
            </Typography>
            </Box>
           {(!tableLoading) ? <EnhancedTable
                rowsData={data}
                handleTableLoading={handleTableLoading}
                loading={tableLoading}
                handleOpenDialog={handleConfirmReschedule}
                handleOpenScheduleDialog={handleConfirmSchedule}
                headCells={headCells}
                menu={menu}
                setPage={setPage}
                perPage={perPage}
                page={page}
                field={field}
                setField={setField}
                total={total}
                sort={sort}
                setSort={setSort}
                setPerPage={setPerPage}
                setOpenNote={setOpenNote}
                ></EnhancedTable> : <Box className={classes.loadingBox}><CircularProgress className={classes.loading}/></Box>}
                 <ConfirmationDialog
                    open={openDialog}
                    handleClose={handleCloseDialog}
                    handleConfirm={handleConfirmReschedule}
                    confirmationButtonText={t('Confirm')}
                    cancelButtonText={t('Cancel')}
                    dialogTitle={t('Reschedule')}
                    dialogContentText={t('Are-you-sure-you-want-to-reschedule?')}
                    
                />
                 <ConfirmationDialog
                    open={openScheduleDialog}
                    handleClose={handleCloseScheduleDialog}
                    handleConfirm={handleConfirmSchedule}
                    confirmationButtonText={t('Confirm')}
                    cancelButtonText={t('Cancel')}
                    dialogTitle={t('Schedule')}
                    dialogContentText={t('Are-you-sure-you-want-to-Schedule?')}
            
                />
                 <ConfirmationDialog  open={openNote>0}
                 handleOutsideClick={()=>{handleOutsideClick()}}
                 handleClose={()=>{handleClose()}}
                 handleConfirm={()=>{handleConfirm()}}
                 notes={true}
                 confirmationButtonText={openEditNote||openAddNote ? t("Save")  : ""}
                 cancelButtonText=  {openEditNote||openAddNote ? t("Cancel")  : ""}  
                 dialogTitle= {openEditNote ? t("Edit-Note")  :openAddNote? t("Add-Note") : t("Notes")}  
                 dialogContentText=""
                 loading={notificationLoading}
                  > {openEditNote  ? 
                  <Edit data={editNote} setData={setEditNote} />
                  : openAddNote ? <Edit data={newNote} setData={setNewNote} />:<><Note  setOpenEditNote={setOpenEditNote} data={notes} setnotificationLoading={setnotificationLoading}/>
                   <Button disabled={clinicImpersonated()} className={`${classes.addNote} ${i18n.language == 'ar' ? classes.fontFamilyAlmarai : classes.fontFamilyEina}`} color="secondary" variant='contained' onClick={()=>{setOpenAddNote(1)}}>{t('Add-Note')}</Button></>}</ConfirmationDialog>
        </Box>
    )
}