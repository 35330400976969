import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { clinicImpersonated, scanType } from "../../util/functions";
import { useTranslation } from "react-i18next";
import ProcedureHeader from "./procedure-header.component";
import ScanFiles from "./ScanFiles";

const useStyles = makeStyles(() => {
  return {
    fontFamilyAlmarai: {
      fontFamily: "Almarai-semi !important",
    },
    fontFamilyEina: {
      fontFamily: "Eina-semi !important",
    },
    wrapper: {
      padding: "2rem",
      boxShadow:
        "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
    },
    box: {
      display: "flex",
      flexWrap: "wrap",
    },
    titleStyle: {
      height: "16px",
      color: "#303030",
      fontSize: "16px !important",
      letterSpacing: 0,
      lineHeight: "22px",
    },
    titleBox: {
      height: "48px",
      width: "360px",
    },
    replacedTitleBox: {
      marginTop: '2rem',
      marginBottom: '1rem',
    },
    substituteTitleBox: {
      marginTop: '1rem',
      marginBottom: '1rem',
    },
    uploadBox: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
    },
    saveBtn: {
      display: "block",
      width: "65px",
      marginTop: "0.5rem",
      marginLeft: "auto",
      borderRadius: "4.7px",
      color: "#fff",
      backgroundColor: "#FF2C46",
      "&:hover": {
        backgroundColor: "#FF2C46",
      },
    },
    loadingBox: {
      margin: "0 auto",
      textAlign: "center",
      padding: 30,
      color: "#FF2C46",
    },
    loading: {
      textAlign: "center",
      margin: "0 auto",
      color: "#ff2c46",
    },
    errorMsg: {
      color: "#D72C0D",
      fontSize: "12px !important",
      marginTop: "1rem",
    },
  };
});

export default function AppointmentScans({
  customStyle = {},
  userId,
  disable = false,
  data = { data: {}, isAllProvided: null },
  handleTabsExpand = () => {},
  index = 0,
  appointmentId = null,
  appointmentType = "scans",
  openCloseTabs,
  scrollToExpandedTab,
}) {
  const { data: scansData, isAllProvided, scanner_placeholders } = data;
  const classes = useStyles();
  const [iconScan, setIconScan] = useState(isAllProvided);
  const [success, setSuccess] = useState(false);
  const [imagePreview, setImagePreview] = useState(scansData);

  const [errorMsg, setErrorMsg] = useState("");
  const { t, i18n } = useTranslation();

  const handleDataFetched = (value) => {
    if (value.data) {
      setImagePreview({
        ...value.data,
      });
      setIconScan(value?.isAllProvided);
    }
  };

  useEffect(() => {
    scrollToExpandedTab(index, "scans");
  }, [openCloseTabs]);

  return (
    <ProcedureHeader
      handleTabsExpand={handleTabsExpand}
      type="scans"
      headerTitle={scanType(appointmentType?.procedure)}
      index={index}
      iconCheck={iconScan}
    >
      {openCloseTabs[index]?.scans && (
        <Box
          style={customStyle}
          className={`${classes.wrapper} scans-${index}`}
        >
          {
            <>
              <Box className={classes.titleBox}>
                <Typography
                  className={`${classes.titleStyle} ${
                    i18n.language === "ar"
                      ? classes.fontFamilyAlmarai
                      : classes.fontFamilyEina
                  }`}
                >
                  {t("Upload-Scans")}
                </Typography>
              </Box>
              <ScanFiles
                imagePreview={imagePreview}
                appointmentType={appointmentType}
                disable={disable}
                setSuccess={setSuccess}
                appointmentId={appointmentId}
                handleDataFetched={handleDataFetched}
                userId={userId}
                classes={classes}
                setErrorMsg={setErrorMsg}
                scannerPlaceholders={scanner_placeholders}
                t={t}
              />
              <Box className={classes.uploadBox}>
                <Button
                  disabled={clinicImpersonated()}
                  className={classes.saveBtn}
                  onClick={() => {}}
                >
                  {success ? t("Saved") : t("Save")}
                </Button>
                <Typography className={classes.errorMsg}>{errorMsg}</Typography>
              </Box>
            </>
          }
        </Box>
      )}
    </ProcedureHeader>
  );
}
